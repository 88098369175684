export interface Image {
  id?: string;
  title?: string;
  altText?: string;
  url?: string;
  thumbUrl?: string;
  base64?: string;
  mime?: string;
  w: number;
  h: number;
}

export class Image implements Image {
  constructor() {
    this.base64 = null;
    this.mime = null;
    this.title = null;
    this.altText = null;
    this.url = null;
    this.thumbUrl = null;
    this.id = null;
    this.h = 0;
    this.w = 0;
  }
}
