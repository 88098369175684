import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Destination } from './../../models/destination.model';
import { HttpResponse } from '@angular/common/http';
import { DestinationsService } from './../../services/destinations.service';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-destination-edit-dialog',
  templateUrl: './destination-edit-dialog.component.html',
  styleUrls: ['./destination-edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DestinationEditDialogComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  loading = true;
  saving = false;
  destinationId?: string;
  destination?: Destination;
  form: FormGroup;
  nameForm: FormControl;
  idForm: FormControl;

  constructor(
    private dialogRef: MatDialogRef<DestinationEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { type: 'edit' | 'new'; id?: string },
    private destinationsService: DestinationsService,
    private snackbar: MatSnackBar
  ) {
    this.destinationId = data.id;
    if (data.type === 'new') {
      this.loading = false;
      this.destinationId = null;
    }
  }

  ngOnInit(): void {
    this.createForm();

    if (this.destinationId) {
      this.destinationsService
        .findDestinationById(this.destinationId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: HttpResponse<Destination>) => {
          if (response.ok) {
            this.destination = response.body;
            this.form.setValue(this.destination);
          }
          this.loading = false;
        });
    } else {
      this.destination = new Destination();
      this.form.setValue(this.destination);
    }
  }

  createForm(): void {
    this.nameForm = new FormControl(null, Validators.required);
    this.idForm = new FormControl(this.destinationId);
    this.form = new FormGroup({
      id: this.idForm,
      name: this.nameForm,
    });
  }

  save(): void {
    if (this.form.valid) {
      this.saving = true;

      if (this.destinationId) {
        this.destinationsService
          .updateDestination(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<Destination>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.dialogRef.close();
            }
          });
      } else {
        this.destinationsService
          .createDestination(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<Destination>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.dialogRef.close();
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
