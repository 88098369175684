import { Subject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import * as CC from 'currency-codes';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormControl,
  FormBuilder,
  NgControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from '@angular/material/form-field';
import { Money } from 'src/app/models/money.model';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-money-form-control',
  templateUrl: './money-form-control.component.html',
  styleUrls: ['./money-form-control.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: MoneyFormControlComponent },
  ],
  host: {
    '[id]': 'id',
  },
})
export class MoneyFormControlComponent
  implements ControlValueAccessor, MatFormFieldControl<Money>, OnDestroy {
  static nextId = 0;
  @ViewChild('amountInput') amountInput: HTMLInputElement;
  @ViewChild('currencySelect') currencyInput: HTMLInputElement;
  id = `app-money-input-${MoneyFormControlComponent.nextId++}`;
  amount: string;
  currency: string;

  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'app-money-input';
  currenciesCodes = CC.codes();
  onChange = (_: any) => {};
  onTouched = () => {};

  @Input()
  get value(): Money | null {
    if (this.amount !== null && this.amount !== '' && this.currency) {
      const amount = Math.round(parseFloat(this.amount) * 100);
      return new Money(amount, this.currency);
    }
    return null;
  }
  set value(money: Money | null) {
    if (money === null) {
      this.amount = '';
      this.currency = '';
      return;
    }

    money = money || new Money();
    this.amount = (money.amount / 100).toString();
    this.currency = money.currency;
  }

  get errorState(): boolean {
    return (
      this.required && ((!this.amount && this.amount !== '0') || !this.currency)
    );
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(money: Money | null): void {
    this.value = money;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get empty() {
    return !this.amount && this.amount !== '0';
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty; //(!this.value?.amount && this.value?.amount !== 0);
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  _disabled = false;

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.money-form-control-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    // if (this.form.controls.amount.valid) {
    //   this._focusMonitor.focusVia(this.amountInput, 'program');
    // } else if (this.form.controls.currency.valid) {
    //   this._focusMonitor.focusVia(this.currencyInput, 'program');
    // } else {
    //   this._focusMonitor.focusVia(this.amountInput, 'program');
    // }
  }

  onDataChange(): void {
    this.onChange(this.value);
    this.stateChanges.next();
  }
}
