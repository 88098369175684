import { HttpResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, Event } from '@angular/router';
import { SettingsElement } from 'src/app/models/settings-element.model';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  dataSource: MatTableDataSource<SettingsElement>;
  displayedColumns: string[] = ['name', 'value', 'actions'];
  loading = true;
  pageIndex = 0;
  perPage = 25;
  totalItems = 0;
  orderBy = 'name';
  orderDir = 'asc';
  searchBy = '';

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.settingsService.writeOp$.subscribe((writeOp) => {
      this.loadSettings();
    });

    this.dataSource = new MatTableDataSource();
    this.loadSettings();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.dataSource.disconnect();
    this.dataSource.data = [];
  }

  loadSettings(): void {
    this.loading = true;
    this.dataSource.data = [];

    this.settingsService
      .findSettingsElements(
        this.orderBy,
        this.orderDir,
        this.pageIndex + 1,
        this.perPage,
        [{ filterName: 'filter[name][phrase]', filterValue: this.searchBy }]
      )
      .pipe(finalize(() => (this.loading = false)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<SettingsElement[]>) => {
        const totalHeader = response.headers.get('x-total');
        const pageHeader = response.headers.get('x-page');
        const perPageHeader = response.headers.get('x-per-page');
        this.totalItems = parseInt(totalHeader, 10);
        this.perPage = parseInt(perPageHeader, 10);
        this.pageIndex = parseInt(pageHeader, 10) - 1;
        if (response.ok) {
          this.dataSource.data = response.body;
        }
      });
  }

  onSortChange(event: Sort): void {
    this.orderBy = event.active;
    this.orderDir = event.direction;
    this.pageIndex = 0;
    this.loadSettings();
  }

  onPaginateChange(event: PageEvent): void {
    this.perPage = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadSettings();
  }

  onSearchChange(search: string): void {
    this.searchBy = search;
    this.loadSettings();
  }
}
