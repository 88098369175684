import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Destination } from '../models/destination.model';
import { hrtime } from 'process';

declare var API_URL: string;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router
  ) {}


  private handleError(error: HttpErrorResponse, obs :Observable<any>, stop = false): Observable<any> {

    let errorMessage = 'Unknown error!';

    if (error.status === 401) {
      this.authService.logout();
      this.snackBar.open('Sesja wygasła, brak autoryzacji', null, {
        duration: 3000,
      });

      return throwError(error);
    }

    if (error.status === 403) {
      this.router.navigate(['']);
      this.snackBar.open('Brak dostępu', null, {
        duration: 3000,
      });

      return throwError(error);
    }

    if (error.status === 404) {
      this.router.navigate(['']);
      this.snackBar.open('404: Element nie istnieje', null, {
        duration: 3000,
      });

      return throwError(error);
    }

    if (error.status === 400) {
      this.snackBar.open(`Niepoprawne dane: ${error.error.message}`, null, {
        duration: 7000,
      });

      return throwError(error);
    }

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;

      this.snackBar.open(`Błąd: ${error.error.message}`, null, {
        duration: 5000,
      });
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

      this.snackBar.open(
        'Wystąpił błąd połączenia sieciowego. Sprawdź połaczenie i spróbuj ponownie',
        null,
        {
          duration: 5000,
        }
      );
    }

    console.error(errorMessage);

    if(stop){
      return throwError(errorMessage);
    }

    return obs;
  }

  private handleErrorAndGo(error: HttpErrorResponse, obs :Observable<any>): Observable<any> {
    return this.handleError(error, obs, false);
  }

  private handleErrorAndStop(error: HttpErrorResponse, obs :Observable<any>): Observable<any> {
    return this.handleError(error, obs, true);
  }

  public putRequest(
    path: string,
    data: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      responseType?: 'json';
    }
  ): Observable<HttpResponse<any>> {
    return this.httpClient
      .put(API_URL + path, data, {
        headers: this.addAuthHeader(options?.headers),
        observe: 'response',
        params: options?.params,
        responseType: options?.responseType,
      })
      .pipe(catchError(this.handleErrorAndStop.bind(this)));
  }

  public postRequest(
    path: string,
    data: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      responseType?: 'json';
    }
  ): Observable<HttpResponse<any>> {
    return this.httpClient
      .post(API_URL + path, data, {
        headers: this.addAuthHeader(options?.headers),
        observe: 'response',
        params: options?.params,
        responseType: options?.responseType,
      })
      .pipe(catchError(this.handleErrorAndStop.bind(this)));
  }

  public getRequest(
    path: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      responseType?: 'json';
    }
  ): Observable<HttpResponse<any>> {
    return this.httpClient
      .get(API_URL + path, {
        headers: this.addAuthHeader(options?.headers),
        observe: 'response',
        params: options?.params,
        responseType: options?.responseType,
      })
      .pipe(catchError(this.handleErrorAndStop.bind(this)));
  }

  public deleteRequest(
    path: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      responseType?: 'json';
    }
  ): Observable<HttpResponse<any>> {
    return this.httpClient
      .delete(API_URL + path, {
        headers: this.addAuthHeader(options?.headers),
        observe: 'response',
        params: options?.params,
        responseType: options?.responseType,
      })
      .pipe(catchError(this.handleErrorAndStop.bind(this)));
  }

  public rawPostRequest(
    path: string,
    body: any | null,
    options: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<HttpResponse<any>> {
    return this.httpClient.post(API_URL + path, body, options);
  }

  private addAuthHeader(
    headers: any
  ):
    | HttpHeaders
    | {
        [header: string]: string | string[];
      } {
    if (!this.authService.isAuthenticated) {
      return headers;
    }

    if (typeof headers === 'undefined' || !headers) {
      headers = {};
    }

    if (headers instanceof HttpHeaders && headers.has('Authorization')) {
      headers.set('Authorization', this.authService.authHeader());
    } else if (
      typeof headers.Authorization === 'undefined' ||
      !headers.Authorization
    ) {
      headers.Authorization = this.authService.authHeader();
    }

    return headers;
  }
}
