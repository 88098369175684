import { Image } from './image.model';

export interface TeamMember {
  id?: string;
  name: string;
  description: string;
  position: number;
  photo?: Image;
}

export class TeamMember implements TeamMember {
  constructor() {
    this.id = null;
    this.photo = null;
    this.position = 99;
    this.name = '';
    this.description = '';
  }
}
