import { Subject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import * as CC from 'currency-codes';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  AfterViewInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormControl,
  FormBuilder,
  NgControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-string-array-form-control',
  templateUrl: './string-array-form-control.component.html',
  styleUrls: ['./string-array-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: StringArrayFormControlComponent,
    },
  ],
  host: {
    '[id]': 'id',
  },
})
export class StringArrayFormControlComponent
  implements ControlValueAccessor, MatFormFieldControl<string[]>, OnDestroy {
  static nextId = 0;
  @ViewChild('createInput') createInput: HTMLInputElement;

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // TODO: disable file upload
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string[] | null {
    return this.items;
  }
  set value(stringArray: string[] | null) {
    stringArray = stringArray || [];
    this.items = stringArray;
  }

  get errorState(): boolean {
    return false; //this.items.url === '' && this.required;
  }

  get empty(): boolean {
    return false; //this.items.url === '';
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  items: string[] = [];

  id = `app-string-array-input-${StringArrayFormControlComponent.nextId++}`;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'app-string-array-input';

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(stringArray: string[] | null): void {
    this.items = stringArray || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onContainerClick(): void {}

  onChange = (_: any) => {};

  onTouched = () => {};

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.string-array-form-control-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onStringArrayChange(event): void {
  }

  onAddElementSubmit(newElment: string): void {
    this.items.push(newElment);
    this.onChange(this.items);
  }

  deleteItem(index: number) {
    this.items.splice(index, 1);
    this.onChange(this.items);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.onChange(this.items);
  }
}
