import { Money } from './money.model';
import { Image } from './image.model';
import { Destination } from './destination.model';
import { RoutePoint } from './route-point.model';
import { ScheduleElement } from './schedule-element.model';

export interface Video {
  id?: string;
  title: string;
  youTubeUrl: string;
}

export class Video implements Video {
  constructor() {
    this.id = null;
    this.title = '';
    this.youTubeUrl = '';
  }
}
