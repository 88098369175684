import { Image } from './../../models/image.model';
import { DestinationsService } from './../../services/destinations.service';
import { Destination } from './../../models/destination.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Tour } from './../../models/tour.model';
import { HttpResponse } from '@angular/common/http';
import { ToursService } from './../../services/tours.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import * as moment from 'moment';

@Component({
  selector: 'app-tour-edit',
  templateUrl: './tour-edit.component.html',
  styleUrls: ['./tour-edit.component.scss'],
})
export class TourEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  tourId?: string;
  tour?: Tour;
  loading = true;
  saving = false;
  possibleTourTypes = ['tramping', 'premium'];
  possibleTourKinds = [
//    'motorcycle',
//    'diving',
//    'surfing',
//    'kitesurfing-windsurfing',
//    'via-ferrata',
//    'skitouring',
    'adventure',
//    'cooking',
//    'skiing',
//    'joga',
    'nature',
    'road-trip',
    'relax',
    'culture',
//    'family',
    'trekking',
    'photoshooting',
    'sport',
    'safari'
  ];
  possibleTourDifficulties = ['easy', 'medium', 'hard', 'extreme'];
  form: FormGroup;
  nameForm: FormControl;
  skaleoButtonCodeForm: FormControl;
  shortNameForm: FormControl;
  slugForm: FormControl;
  descriptionForm: FormControl;
  kindsForm: FormControl;
  typeForm: FormControl;
  difficultyForm: FormControl;
  basePriceForm: FormControl;
  additionalPriceForm: FormControl;
  photoForm: FormControl;
  destinationForm: FormControl;
  participantsLimitForm: FormControl;
  dateStartForm: FormControl;
  durationForm: FormControl;
  prosForm: FormControl;
  priceIncludeForm: FormControl;
  priceExcludeForm: FormControl;
  routePointsForm: FormControl;
  routeDescriptionForm: FormControl;
  scheduleForm: FormControl;
  infoSectionsForm: FormControl;
  idForm: FormControl;
  metaDescriptionForm: FormControl;
  metaKeywordsForm: FormControl;
  ogTitleForm: FormControl;
  ogDescriptionForm: FormControl;
  videosForm: FormControl;
  searching = false;
  destinationsFilteringForm: FormControl;
  destinationsFilteringFormOpened = false;
  destinationsFiltered: Destination[] = [];
  destinationsFilteredPage = 1;
  destinationsFilteredLastPage = 1;

  @ViewChild('matSelectInfiniteScroll', { static: true })
  infiniteScrollSelect: MatSelect;

  constructor(
    private destinationsService: DestinationsService,
    private toursService: ToursService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tour = new Tour();
    this.createForm();

    this.route.params.subscribe((params) => {
      const editingTourId = params.id;

      if (editingTourId !== 'new') {
        this.loading = true;
        this.tourId = editingTourId;
        this.toursService
          .findTourById(this.tourId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<Tour>) => {
            if (response.ok) {
              this.tour = response.body;
              if (this.tour.destination) {
                this.destinationsFiltered.push(this.tour.destination);
              }
              this.form.setValue(this.tour);
              this.loading = false;
            }
          });
      } else {
        this.route.queryParamMap.subscribe((queryParams) => {
          const copyOfTour = queryParams.get('copyOfTour');
          if (!copyOfTour || copyOfTour === '') {
            this.loading = false;
            this.form.setValue(this.tour);
          } else {
            console.log('Loading tour to copy');
            this.loading = true;
            this.toursService
              .findTourById(copyOfTour)
              .pipe(takeUntil(this.destroy$))
              .subscribe((response: HttpResponse<Tour>) => {
                if (response.ok) {
                  this.tour = response.body;
                  this.tour.slug = '';
                  this.tour.copyOf = this.tour.id;
                  this.tour.id = null;
                  this.deepClearRelationIds(this.tour);
                  if (this.tour.destination) {
                    this.destinationsFiltered.push(this.tour.destination);
                  }
                  this.form.setValue(this.tour);
                  this.loading = false;
                }
              });
          }
        });
      }
    });

    this.destinationsFilteringForm.valueChanges.subscribe((value) => {
      if (!this.destinationsFilteringFormOpened) {
        return;
      }

      this.searching = true;
      this.destinationsService
        .findDestinations('name', 'asc', 1, 10, [
          { filterName: 'filter[name][phrase]', filterValue: value },
        ])
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: HttpResponse<Destination[]>) => {
          if (response.ok) {
            this.destinationsFiltered = response.body;
          }
          this.destinationsFilteredPage = 1;
          const lastPageHeader = response.headers.get('x-last-page');
          this.destinationsFilteredLastPage = parseInt(lastPageHeader, 10);

          this.searching = false;
        });
    });
  }

  deepClearRelationIds(tour: Tour): void {
    if (tour.infoSections !== null) {
      for (let iS of tour.infoSections) {
        iS.id = null;
      }
    }

    if (tour.videos !== null) {
      for (let v of tour.videos) {
        v.id = null;
      }
    }

    if (tour.routePoints !== null) {
      for (let rP of tour.routePoints) {
        rP.id = null;
      }
    }

    if (tour.schedule !== null) {
      for (let sE of tour.schedule) {
        sE.id = null;
      }
    }
  }

  createForm(): void {
    this.nameForm = new FormControl(null, Validators.required);
    this.shortNameForm = new FormControl(null, Validators.required);
    this.slugForm = new FormControl(null, [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9_-]+'),
    ]);
    this.idForm = new FormControl(this.tourId);
    this.descriptionForm = new FormControl();
    this.typeForm = new FormControl();
    this.kindsForm = new FormControl();
    this.difficultyForm = new FormControl();
    this.basePriceForm = new FormControl();
    this.additionalPriceForm = new FormControl();
    this.photoForm = new FormControl();
    this.destinationForm = new FormControl();
    this.participantsLimitForm = new FormControl();
    this.dateStartForm = new FormControl();
    this.durationForm = new FormControl();
    this.prosForm = new FormControl();
    this.priceIncludeForm = new FormControl();
    this.priceExcludeForm = new FormControl();
    this.routePointsForm = new FormControl();
    this.routeDescriptionForm = new FormControl();
    this.scheduleForm = new FormControl();
    this.infoSectionsForm = new FormControl();
    this.metaDescriptionForm = new FormControl();
    this.metaKeywordsForm = new FormControl();
    this.ogTitleForm = new FormControl();
    this.ogDescriptionForm = new FormControl();
    this.videosForm = new FormControl();
    this.skaleoButtonCodeForm = new FormControl();

    this.form = new FormGroup({
      id: this.idForm,
      copyOf: new FormControl(),
      shortName: this.shortNameForm,
      slug: this.slugForm,
      name: this.nameForm,
      description: this.descriptionForm,
      type: this.typeForm,
      kinds: this.kindsForm,
      difficulty: this.difficultyForm,
      basePrice: this.basePriceForm,
      additionalPrice: this.additionalPriceForm,
      photo: this.photoForm,
      destination: this.destinationForm,
      participantsLimit: this.participantsLimitForm,
      dateStart: this.dateStartForm,
      duration: this.durationForm,
      pros: this.prosForm,
      priceInclude: this.priceIncludeForm,
      priceExclude: this.priceExcludeForm,
      routePoints: this.routePointsForm,
      routeDescription: this.routeDescriptionForm,
      schedule: this.scheduleForm,
      infoSections: this.infoSectionsForm,
      metaDescription: this.metaDescriptionForm,
      metaKeywords: this.metaKeywordsForm,
      ogTitle: this.ogTitleForm,
      ogDescription: this.ogDescriptionForm,
      videos: this.videosForm,
      skaleoButtonCode: this.skaleoButtonCodeForm
    });

    this.destinationsFilteringForm = new FormControl();
  }

  save(): void {
    if (this.form.valid) {
      this.saving = true;

      if (this.tourId) {
        this.toursService
          .updateTour(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<Tour>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.router.navigateByUrl('/tours');
            }
          });
      } else {
        this.toursService
          .createTour(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<Tour>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.router.navigateByUrl('/tours');
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  compareDestinations(o1: any, o2: any): boolean {
    return (
      typeof o1 === typeof o2 &&
      ((o1 === null && o2 === null) || (o1 !== null && o2 !== null)) &&
      o1.id === o2.id
    );
  }

  getMoreDestination(): void {
    this.destinationsFilteredPage += 1;
    this.searching = true;
    this.destinationsService
      .findDestinations('name', 'asc', this.destinationsFilteredPage, 10, [
        {
          filterName: 'filter[name][phrase]',
          filterValue: this.destinationsFilteringForm.value,
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<Destination[]>) => {
        if (response.ok) {
          this.destinationsFiltered = [
            ...this.destinationsFiltered,
            ...response.body,
          ];
          this.destinationForm.updateValueAndValidity();
        }
        this.searching = false;
      });
  }

  destinationFormOpenedChange(opened: boolean): void {
    this.destinationsFilteringFormOpened = opened;
  }
}
