import { SettingsElement } from './../../models/settings-element.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { SettingsService } from '../../services/settings.service';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-settings-edit-dialog',
  templateUrl: './settings-edit-dialog.component.html',
  styleUrls: ['./settings-edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsEditDialogComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  loading = true;
  saving = false;
  settingsElementId?: string;
  settingsElement?: SettingsElement;
  form: FormGroup;
  nameForm: FormControl;
  valueForm: FormControl;
  typeForm: FormControl;
  idForm: FormControl;

  constructor(
    private dialogRef: MatDialogRef<SettingsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { type: 'edit' | 'new'; id?: string },
    private settingsService: SettingsService,
    private snackbar: MatSnackBar
  ) {
    this.settingsElementId = data.id;
    if (data.type === 'new') {
      this.loading = false;
      this.settingsElementId = null;
    }
  }

  ngOnInit(): void {
    this.createForm();

    if (this.settingsElementId) {
      this.settingsService
        .findSettingsElementById(this.settingsElementId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: HttpResponse<SettingsElement>) => {
          if (response.ok) {
            this.settingsElement = response.body;
            this.form.setValue(this.settingsElement);
          }
          this.loading = false;
        });
    } else {
      this.settingsElement = new SettingsElement();
      this.form.setValue(this.settingsElement);
    }
  }

  createForm(): void {
    this.valueForm = new FormControl(null, Validators.required);
    this.idForm = new FormControl(this.settingsElementId);
    this.nameForm = new FormControl();
    this.typeForm = new FormControl();
    this.form = new FormGroup({
      id: this.idForm,
      name: this.nameForm,
      value: this.valueForm,
      type: this.typeForm,
    });
  }

  save(): void {
    if (this.form.valid) {
      this.saving = true;

      if (this.settingsElementId) {
        this.settingsService
          .updateSettingsElement(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<SettingsElement>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.dialogRef.close();
            }
          });
      } else {
        this.snackbar.open('Coś poszło nie tak!', null, {
          duration: 1500,
          panelClass: 'success-snackbar',
        });
        this.dialogRef.close();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
