import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Destination } from './../../models/destination.model';
import { HttpResponse } from '@angular/common/http';
import { DestinationsService } from './../../services/destinations.service';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-destination-delete-dialog',
  templateUrl: './destination-delete-dialog.component.html',
  styleUrls: ['./destination-delete-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DestinationDeleteDialogComponent implements OnInit, OnDestroy {
  loading = true;
  deleting = false;
  destinationId?: string;
  destination?: Destination;
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<DestinationDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { id?: string },
    private destinationsService: DestinationsService,
    private snackbar: MatSnackBar
  ) {
    this.destinationId = data.id;
  }

  ngOnInit(): void {
    this.destinationsService
      .findDestinationById(this.destinationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<Destination>) => {
        if (response.ok) {
          this.destination = response.body;
        }
        this.loading = false;
      });
  }

  delete(): void {
    this.deleting = true;

    this.destinationsService
      .deleteDestination(this.destination)
      .pipe(finalize(() => (this.deleting = false)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<Destination[]>) => {
        if (response.ok) {
          this.snackbar.open('Usunięto', null, {
            duration: 1500,
            panelClass: 'success-snackbar',
          });
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
