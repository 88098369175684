<div class="dialog">
  <div mat-dialog-title>
    <h1>Edycja ustawień</h1>
  </div>
  <div mat-dialog-content>
    <mat-spinner *ngIf="loading"></mat-spinner>

    <div class="container" *ngIf="!loading">
      <form novalidate [formGroup]="form" (ngSubmit)="save()">
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>{{ settingsElement?.name }}</mat-label>
          <input
            matInput
            [type]="settingsElement?.type"
            placeholder="wartość..."
            formControlName="value"
            required
          />
          <mat-error *ngIf="nameForm.errors?.required"
            >To pole nie może być puste</mat-error
          >
        </mat-form-field>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <div flex fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <button mat-button mat-dialog-close>Anuluj</button>
      <button
        mat-raised-button
        color="primary"
        [class.save-btn-spinner]="saving"
        [disabled]="loading || !form.valid || saving"
        (click)="save()"
      >
        Zapisz
      </button>
    </div>
  </div>
</div>
