<div
  role="group"
  class="route-point-array-form-control-container"
  [attr.aria-labelledby]="_formField?.getLabelId()"
>
  <div id="map" class="map" #mapElement></div>
  <div>
    <button mat-button color="warn" type="button" (click)="clear()">
      <mat-icon>delete</mat-icon>
      Wyczyść
    </button>
  </div>
</div>
