import { Icon } from './../../models/icon.model';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent implements OnInit {
  @Input()
  icon: Icon;

  constructor() {}

  ngOnInit(): void {}
}
