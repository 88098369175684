import { BlogPost } from './../models/blog-post.model';
import { GalleryElement } from './../models/gallery-element.model';
import { Tour } from './../models/tour.model';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Page } from '../models/page.model';

@Injectable({
  providedIn: 'root',
})
export class GalleryElementsService {
  private API_ENDPOINT = '/gallery-elements';

  public writeOp$: Subject<{
    op: 'delete' | 'create' | 'update';
    galleryElementId: string;
  }> = new Subject<{
    op: 'delete' | 'create' | 'update';
    galleryElementId: string;
  }>();

  constructor(private api: ApiService) {}

  public createGalleryElement(
    galleryElement: GalleryElement
  ): Observable<HttpResponse<GalleryElement>> {
    return this.api.postRequest(this.API_ENDPOINT, galleryElement, {}).pipe(
      finalize(() =>
        this.writeOp$.next({
          op: 'create',
          galleryElementId: galleryElement.id,
        })
      )
    );
  }

  public updateGalleryElement(
    galleryElement: GalleryElement
  ): Observable<HttpResponse<GalleryElement>> {
    return this.api
      .putRequest(
        this.API_ENDPOINT + '/' + galleryElement.id,
        galleryElement,
        {}
      )
      .pipe(
        finalize(() =>
          this.writeOp$.next({
            op: 'update',
            galleryElementId: galleryElement.id,
          })
        )
      );
  }

  public deleteGalleryElement(
    galleryElement: GalleryElement
  ): Observable<HttpResponse<GalleryElement>> {
    return this.api
      .deleteRequest(this.API_ENDPOINT + '/' + galleryElement.id, {})
      .pipe(
        finalize(() =>
          this.writeOp$.next({
            op: 'delete',
            galleryElementId: galleryElement.id,
          })
        )
      );
  }

  public findGalleryElementsForTour(
    tour: Tour
  ): Observable<HttpResponse<GalleryElement[]>> {
    return this.api.getRequest(this.API_ENDPOINT + '/by-tour/' + tour.id, {});
  }

  public findGalleryElementsForBlogPost(
    blogPost: BlogPost
  ): Observable<HttpResponse<GalleryElement[]>> {
    return this.api.getRequest(
      this.API_ENDPOINT + '/by-blog-post/' + blogPost.id,
      {}
    );
  }

  public findGalleryElementsForPage(
    page: Page
  ): Observable<HttpResponse<GalleryElement[]>> {
    return this.api.getRequest(this.API_ENDPOINT + '/by-page/' + page.id, {});
  }
}
