<div class="dialog">
  <h1 mat-dialog-title>Usuwanie subskrybenta newslettera</h1>
  <div mat-dialog-content>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <p *ngIf="!loading">
      Czy na pewno chcesz usunąć: <br />
      <i>{{ newsletterSubscriber?.email }}</i>
    </p>
  </div>
  <div mat-dialog-actions>
    <div flex fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <button mat-button mat-dialog-close>Anuluj</button>
      <button
        mat-raised-button
        color="warn"
        [class.delete-btn-spinner]="deleting"
        [disabled]="loading || deleting"
        (click)="delete()"
      >
        <mat-icon>delete</mat-icon> Usuń
      </button>
    </div>
  </div>
</div>
