<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2 [hidden]="loading">
        {{ blogPostId ? "Edycja wpisu" : "Tworzenie wpisu" }}
      </h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-spinner *ngIf="loading"></mat-spinner>

    <div *ngIf="!loading">
      <form novalidate [formGroup]="form" fxLayout="column" (ngSubmit)="save()">
        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Tytuł</mat-label>
            <input
              matInput
              placeholder="Tytuł wpisu"
              formControlName="title"
              required
            />
            <mat-error *ngIf="titleForm.errors?.required"
              >To pole nie być puste</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Data wpisu</mat-label>
            <input
              matInput
              formControlName="createdAt"
              [matDatepicker]="picker1"
              (focus)="picker1.open()"
              (click)="!picker1.opened && picker1.open()"
            />
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Slug (URL)</mat-label>
            <input
              matInput
              placeholder="element-adresu-wpisu-na-blogu"
              formControlName="slug"
              required
            />
            <mat-error *ngIf="slugForm.errors?.required"
              >To pole nie być puste</mat-error
            >
            <mat-error *ngIf="slugForm.errors?.value"
              >To pole może zawierać tylko litery, cyfry i myślniki</mat-error
            >
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Zdjęcie</mat-label>
            <app-image-form-control
              formControlName="mainImage"
            ></app-image-form-control>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>OG - Tytuł</mat-label>
            <input matInput formControlName="ogTitle" />
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>OG - Opis</mat-label>
            <input matInput formControlName="ogDescription" />
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>META - Opis</mat-label>
            <input matInput formControlName="metaDescription" />
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>META - Słowa kluczowe</mat-label>
            <input matInput formControlName="metaKeywords" />
          </mat-form-field>
        </div>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Podtytuł</mat-label>
          <textarea
            formControlName="subtitle"
            matInput
            placeholder="Podtytuł..."
          ></textarea>
        </mat-form-field>

        <div class="tiny-editor">
          <h4>Treść wpisu:</h4>
          <editor
            [init]="getTinyMceInitData()"
            tinyMatFormControl
            formControlName="content"
            placeholder="Treść wpisu..."
          ></editor>
        </div>
      </form>
    </div>

    <div mat-card-actions>
      <div
        flex
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxFlex="100"
      >
        <a mat-button [routerLink]="'/blog-posts'"> Anuluj </a>

        <button
          mat-raised-button
          color="primary"
          [class.save-btn-spinner]="saving"
          [disabled]="loading || !form.valid || saving"
          (click)="save()"
        >
          Zapisz
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
