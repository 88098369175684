import { ScheduleElement } from '../../../models/schedule-element.model';
import { Icon } from '../../../models/icon.model';
import { HttpResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { IconsService } from '../../../services/icons.service';
import { Subject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import * as CC from 'currency-codes';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  AfterViewInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormControl,
  FormBuilder,
  NgControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-schedule-element-array-form-control',
  templateUrl: './schedule-element-array-form-control.component.html',
  styleUrls: ['./schedule-element-array-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ScheduleElementArrayFormControlComponent,
    },
  ],
  host: {
    '[id]': 'id',
  },
})
export class ScheduleElementArrayFormControlComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<ScheduleElement[]>,
    OnDestroy {
  static nextId = 0;
  openElement = -1;

  setOpenElement(num: number) {
    this.openElement = num;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // TODO: disable file upload
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): ScheduleElement[] | null {
    return this.items;
  }
  set value(scheduleElements: ScheduleElement[] | null) {
    scheduleElements = scheduleElements || [];
    this.items = scheduleElements;
  }

  get errorState(): boolean {
    return false; //this.items.url === '' && this.required;
  }

  get empty(): boolean {
    return false; //this.items.url === '';
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  items: ScheduleElement[] = [];

  id = `app-schedule-element-array-input-${ScheduleElementArrayFormControlComponent.nextId++}`;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'app-schedule-element-array-input';

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private iconsService: IconsService,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(scheduleElements: ScheduleElement[] | null): void {
    this.items = scheduleElements || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }
  destroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.destroy$.next();
    this.destroy$.complete();
  }

  onContainerClick(): void {}

  onChange = (_: any) => {};

  onTouched = () => {};

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.schedule-element-array-form-control-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onScheduleElementArrayChange(): void {
    this.onChange(this.items);
  }

  deleteElement(item: ScheduleElement): void {
    this.items = this.items.filter((obj) => obj !== item);
    this.onChange(this.items);
  }

  addElement(): void {
    let maxDayEnd = 0;
    this.items.forEach((item) => {
      if (item.dayEnd > maxDayEnd) {
        maxDayEnd = item.dayEnd;
      }
    });
    this.openElement =
      this.items.push(new ScheduleElement(maxDayEnd + 1, maxDayEnd + 1)) - 1;
    this.onChange(this.items);
  }

  drop(event: CdkDragDrop<ScheduleElement[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.onChange(this.items);
  }
}
