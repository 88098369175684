export interface SettingsElement {
  id: string;
  type: string;
  name: string;
  value: any;
}

export class SettingsElement implements SettingsElement {
  constructor(
    public id: string = '',
    public type: string = '',
    public name: string = '',
    public value: any = ''
  ) {}
}
