<mat-card>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h2>Logowanie</h2>
      <mat-error *ngIf="loginInvalid"> Błędne dane logowania </mat-error>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Email" formControlName="email" required />
        <mat-error> To pole jest wymagane </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input
          matInput
          type="password"
          placeholder="Hasło"
          formControlName="password"
          required
        />
        <mat-error> To pole jest wymagane </mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        [class.waiting-login-btn-spinner]="waiting"
        [disabled]="waiting"
      >
        Zaloguj
      </button>
    </form>
  </mat-card-content>
</mat-card>
