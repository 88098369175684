import { HttpParams, HttpResponse } from '@angular/common/http';
import { Page } from '../models/page.model';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  private API_ENDPOINT = '/pages';

  public writeOp$: Subject<{
    op: 'update';
    pageId: string;
  }> = new Subject<{
    op: 'update';
    pageId: string;
  }>();

  constructor(private api: ApiService) {}

  public updatePage(page: Page): Observable<HttpResponse<Page>> {
    return this.api
      .putRequest(this.API_ENDPOINT + '/' + page.id, page, {})
      .pipe(
        finalize(() => this.writeOp$.next({ op: 'update', pageId: page.id }))
      );
  }

  public findPages(
    orderBy: string = '',
    orderDir: string = 'asc',
    pageNumber = 1,
    perPage = 25,
    filters: { filterName: string; filterValue: string }[]
  ): Observable<HttpResponse<Page[]>> {
    let params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('perPage', perPage.toString());

    if (
      typeof orderBy !== 'undefined' &&
      orderBy &&
      typeof orderDir !== 'undefined' &&
      orderDir
    ) {
      params = params.set('orderBy', orderBy).set('orderDir', orderDir);
    }

    filters.forEach((filter) => {
      if (typeof filter.filterValue !== 'undefined' && filter.filterValue) {
        params = params.set(filter.filterName, filter.filterValue);
      }
    });

    return this.api.getRequest(this.API_ENDPOINT, { params });
  }

  public findPageById(id: string): Observable<HttpResponse<Page>> {
    return this.api.getRequest(this.API_ENDPOINT + '/' + id, {});
  }
}
