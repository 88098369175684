<div class="dialog">
  <h1 mat-dialog-title>
    {{ destination?.id ? "Edycja kierunku" : "Tworzenie kierunku" }}
  </h1>
  <div mat-dialog-content>
    <mat-spinner *ngIf="loading"></mat-spinner>

    <div class="container" *ngIf="!loading">
      <form novalidate [formGroup]="form" (ngSubmit)="save()">
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Nazwa</mat-label>
          <input
            matInput
            placeholder="Kierunek wyjazdu"
            formControlName="name"
            required
          />
          <mat-error *ngIf="nameForm.errors?.required"
            >To pole nie może być puste</mat-error
          >
        </mat-form-field>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <div flex fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <button mat-button mat-dialog-close>Anuluj</button>
      <button
        mat-raised-button
        color="primary"
        [class.save-btn-spinner]="saving"
        [disabled]="loading || !form.valid || saving"
        (click)="save()"
      >
        Zapisz
      </button>
    </div>
  </div>
</div>
