<div
  role="group"
  class="schedule-element-array-form-control-container"
  [attr.aria-labelledby]="_formField?.getLabelId()"
>
  <div class="to-right">
    <button mat-button color="primary" type="button" (click)="addElement()">
      <mat-icon>add</mat-icon> Dodaj element
    </button>
  </div>

  <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-expansion-panel
      cdkDrag
      [cdkDragData]="item"
      [expanded]="openElement === i"
      (opened)="setOpenElement(i)"
      *ngFor="let item of items; index as i"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span *ngIf="item.dayEnd && item.dayStart !== item.dayEnd">
            Dzień {{ item.dayStart }} - {{ item.dayEnd }}.
          </span>
          <span *ngIf="!item.dayEnd || item.dayStart === item.dayEnd">
            Dzień {{ item.dayStart }}.
          </span>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        fxLayout="row"
        fxLayout.lt-lg="column"
        fxLayoutGap="20px"
        fxLayoutGap.lt-lg="0px"
      >
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Dzień wyjazdu</mat-label>
          <input matInput type="number" [(ngModel)]="item.dayStart" (input)="onScheduleElementArrayChange()" />
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Końcowy dzień wyjazdu</mat-label>
          <input matInput type="number" [(ngModel)]="item.dayEnd" (input)="onScheduleElementArrayChange()" />
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-lg="column"
        fxLayoutGap="20px"
        fxLayoutGap.lt-lg="0px"
      >
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Plan</mat-label>
          <textarea matInput [(ngModel)]="item.description" (input)="onScheduleElementArrayChange()"></textarea>
        </mat-form-field>
      </div>

      <mat-action-row>
        <button
          mat-icon-button
          color="warn"
          type="button"
          (click)="deleteElement(item)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
