<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2 *ngIf="!loading">Edycja galerii wyjazdu {{ page?.title }}</h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div *ngIf="!loading" class="gallery-editor">
      <div fxLayout="row" fxLayoutAlign="start center">
        <input
          #galleryFileInput
          hidden
          class="ng-hide"
          id="file-galler-upload"
          type="file"
          (change)="galleryFilesUpload($event)"
          accept="image/*"
          multiple
        />
        <button
          type="button"
          mat-raised-button
          (click)="galleryFileInput.click()"
          color="primary"
        >
          <mat-icon>add_a_photo</mat-icon>
          Wgraj zdjęcia
        </button>
      </div>
      <div>
        <mat-card class="gallery-card" *ngFor="let element of galleryElements">
          <img
            *ngIf="element.image?.url"
            mat-card-image
            [src]="element.image?.thumbUrl"
            [alt]="'Photo ' + element.image.id"
          />
          <img
            *ngIf="!element.image?.url"
            mat-card-image
            [src]="
              'data:' + element.image?.mime + ';base64,' + element.image?.base64
            "
            [alt]="'Photo ' + element.image.id"
          />

          <form class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>Tytuł</mat-label>
              <input
                type="text"
                matInput
                placeholder="Tytuł"
                [(ngModel)]="element.image.title"
                (change)="updateGalleryElement(element)"
                name="title"
              />
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Pozycja</mat-label>
              <input
                type="number"
                matInput
                placeholder="Pozycja"
                (change)="updateGalleryElement(element)"
                [(ngModel)]="element.position"
                name="position"
              />
            </mat-form-field>
          </form>
          <mat-card-actions>
            <button mat-icon-button (click)="deleteGalleryElement(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <mat-spinner *ngIf="loading"></mat-spinner>

    <div mat-card-actions>
      <div
        flex
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxFlex="100"
      >
        <a mat-button [routerLink]="'/pages'"> Anuluj </a>

        <button
          mat-raised-button
          color="primary"
          [class.save-btn-spinner]="saving"
          [disabled]="loading || saving"
          (click)="save()"
        >
          Zapisz
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
