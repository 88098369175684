import { DestinationDeleteDialogComponent } from './../destination-delete-dialog/destination-delete-dialog.component';
import { HttpResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DestinationsService } from './../../services/destinations.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Destination } from 'src/app/models/destination.model';
import { Subject } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, Event } from '@angular/router';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss'],
})
export class DestinationsComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  dataSource: MatTableDataSource<Destination>;
  displayedColumns: string[] = ['name', 'actions'];
  loading = true;
  pageIndex = 0;
  perPage = 25;
  totalItems = 0;
  orderBy = 'name';
  orderDir = 'asc';
  searchBy = '';

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private destinationsService: DestinationsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.destinationsService.writeOp$.subscribe((writeOp) => {
      this.loadDestinations();
    });

    this.dataSource = new MatTableDataSource();
    this.loadDestinations();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.dataSource.disconnect();
    this.dataSource.data = [];
  }

  loadDestinations(): void {
    this.loading = true;
    this.dataSource.data = [];

    this.destinationsService
      .findDestinations(
        this.orderBy,
        this.orderDir,
        this.pageIndex + 1,
        this.perPage,
        [{ filterName: 'filter[name][phrase]', filterValue: this.searchBy }]
      )
      .pipe(finalize(() => (this.loading = false)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<Destination[]>) => {
        const totalHeader = response.headers.get('x-total');
        const pageHeader = response.headers.get('x-page');
        const perPageHeader = response.headers.get('x-per-page');
        this.totalItems = parseInt(totalHeader, 10);
        this.perPage = parseInt(perPageHeader, 10);
        this.pageIndex = parseInt(pageHeader, 10) - 1;
        if (response.ok) {
          this.dataSource.data = response.body;
        }
      });
  }

  onSortChange(event: Sort): void {
    this.orderBy = event.active;
    this.orderDir = event.direction;
    this.pageIndex = 0;
    this.loadDestinations();
  }

  onPaginateChange(event: PageEvent): void {
    this.perPage = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadDestinations();
  }

  onSearchChange(search: string): void {
    this.searchBy = search;
    this.loadDestinations();
  }

  delete(destination: Destination): void {
    const dialogRef = this.dialog.open(DestinationDeleteDialogComponent, {
      data: { id: destination.id },
    });
    dialogRef.disableClose = true;
  }
}
