<app-header (toggleSidebarFor)="sidebarToggler()"></app-header>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" [opened]="sidebarOpened">
    <app-sidebar></app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
