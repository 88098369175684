import { HttpParams, HttpResponse } from '@angular/common/http';
import { TeamMember } from '../models/team-member.model';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TeamMembersService {
  private API_ENDPOINT = '/team-members';

  public writeOp$: Subject<{
    op: 'delete' | 'create' | 'update';
    teamMemberId: string;
  }> = new Subject<{
    op: 'delete' | 'create' | 'update';
    teamMemberId: string;
  }>();

  constructor(private api: ApiService) {}

  public createTeamMember(
    teamMember: TeamMember
  ): Observable<HttpResponse<TeamMember>> {
    return this.api
      .postRequest(this.API_ENDPOINT, teamMember, {})
      .pipe(
        finalize(() =>
          this.writeOp$.next({ op: 'create', teamMemberId: teamMember.id })
        )
      );
  }

  public updateTeamMember(
    teamMember: TeamMember
  ): Observable<HttpResponse<TeamMember>> {
    return this.api
      .putRequest(this.API_ENDPOINT + '/' + teamMember.id, teamMember, {})
      .pipe(
        finalize(() =>
          this.writeOp$.next({ op: 'update', teamMemberId: teamMember.id })
        )
      );
  }

  public deleteTeamMember(
    teamMember: TeamMember
  ): Observable<HttpResponse<TeamMember[]>> {
    return this.api
      .deleteRequest(this.API_ENDPOINT + '/' + teamMember.id, {})
      .pipe(
        finalize(() =>
          this.writeOp$.next({ op: 'delete', teamMemberId: teamMember.id })
        )
      );
  }

  public findTeamMembers(
    orderBy: string = '',
    orderDir: string = 'asc',
    pageNumber = 1,
    perPage = 25,
    filters: { filterName: string; filterValue: string }[]
  ): Observable<HttpResponse<TeamMember[]>> {
    let params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('perPage', perPage.toString());

    if (
      typeof orderBy !== 'undefined' &&
      orderBy &&
      typeof orderDir !== 'undefined' &&
      orderDir
    ) {
      params = params.set('orderBy', orderBy).set('orderDir', orderDir);
    }

    filters.forEach((filter) => {
      if (typeof filter.filterValue !== 'undefined' && filter.filterValue) {
        params = params.set(filter.filterName, filter.filterValue);
      }
    });

    return this.api.getRequest(this.API_ENDPOINT, { params });
  }

  public findTeamMemberById(id: string): Observable<HttpResponse<TeamMember>> {
    return this.api.getRequest(this.API_ENDPOINT + '/' + id, {});
  }
}
