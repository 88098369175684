export interface Destination {
  id?: string;
  name: string;
}

export class Destination implements Destination {
  id?: string;

  constructor(public name: string = '') {
    this.id = null;
  }
}
