<mat-card>
  <mat-card-header>
    <mat-card-title> Subskrybenci newslettera </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="onSortChange($event)"
        [matSortActive]="orderBy"
        [matSortDirection]="orderDir"
        matSortStart="asc"
        matSortDisableClear
      >
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data zapisania</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="to-right">
            <button
              mat-button
              mat-icon-button
              matTooltip="Usuń"
              (click)="delete(element)"
              class="btn-delete"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-spinner *ngIf="loading"></mat-spinner>

      <mat-paginator
        [pageSizeOptions]="[25, 50, 100]"
        [length]="totalItems"
        [pageSize]="perPage"
        [pageIndex]="pageIndex"
        (page)="onPaginateChange($event)"
        >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<router-outlet></router-outlet>
