import { Image } from './image.model';

export interface Page {
  id?: string;
  metaDescription?: string;
  metaKeywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  slug: string;
  title: string;
  subtitle: string;
  content: string;
  mainImage?: Image;
}

export class Page implements Page {
  constructor() {
    this.id = null;
    this.mainImage = null;
    this.slug = '';
    this.title = '';
    this.subtitle = '';
    this.content = '';
    this.metaDescription = '';
    this.metaKeywords = '';
    this.ogTitle = '';
    this.ogDescription = '';
  }
}
