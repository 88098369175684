import { SettingsEditDialogComponent } from './../settings-edit-dialog/settings-edit-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({ template: '' })
export class SettingsEditDialogOpenerComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const editingSettingsElementId = params.id;

      if (
        typeof editingSettingsElementId !== 'undefined' &&
        editingSettingsElementId &&
        editingSettingsElementId !== ''
      ) {
        const paramData = { type: 'edit', id: editingSettingsElementId };
        const dialogRef = this.dialog.open(SettingsEditDialogComponent, {
          data: paramData,
          panelClass: 'big-dialog',
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigateByUrl('/settings');
        });
      }
    });
  }
}
