import { GalleryElementsService } from './../../services/gallery-elements.service';
import { Image } from '../../models/image.model';
import { DestinationsService } from '../../services/destinations.service';
import { Destination } from '../../models/destination.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize, catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BlogPost } from '../../models/blog-post.model';
import { HttpResponse } from '@angular/common/http';
import { BlogPostsService } from '../../services/blog-posts.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import * as moment from 'moment';
import { GalleryElement } from 'src/app/models/gallery-element.model';

@Component({
  selector: 'app-blog-post-edit',
  templateUrl: './blog-post-edit.component.html',
  styleUrls: ['./blog-post-edit.component.scss'],
})
export class BlogPostEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  blogPostId?: string;
  blogPost?: BlogPost;
  loading = true;
  saving = false;
  form: FormGroup;
  titleForm: FormControl;
  subtitleForm: FormControl;
  slugForm: FormControl;
  contentForm: FormControl;
  mainImageForm: FormControl;
  createdAtForm: FormControl;
  idForm: FormControl;
  metaDescriptionForm: FormControl;
  metaKeywordsForm: FormControl;
  ogTitleForm: FormControl;
  ogDescriptionForm: FormControl;
  searching = false;

  @ViewChild('matSelectInfiniteScroll', { static: true })
  infiniteScrollSelect: MatSelect;

  constructor(
    private destinationsService: DestinationsService,
    private galleryElementsService: GalleryElementsService,
    private blogPostsService: BlogPostsService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.blogPost = new BlogPost();
    this.createForm();

    this.route.params.subscribe((params) => {
      const editingBlogPostId = params.id;

      if (editingBlogPostId !== 'new') {
        this.loading = true;
        this.blogPostId = editingBlogPostId;
        this.blogPostsService
          .findBlogPostById(this.blogPostId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<BlogPost>) => {
            if (response.ok) {
              this.blogPost = response.body;
              this.form.setValue(this.blogPost);
              this.loading = false;
            }
          });
      } else {
        this.loading = false;
        this.form.setValue(this.blogPost);
      }
    });
  }

  createForm(): void {
    this.titleForm = new FormControl(null, Validators.required);
    this.slugForm = new FormControl(null, [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9_-]+'),
    ]);
    this.idForm = new FormControl(this.blogPostId);
    this.subtitleForm = new FormControl();
    this.createdAtForm = new FormControl();
    this.contentForm = new FormControl();
    this.mainImageForm = new FormControl();
    this.metaDescriptionForm = new FormControl();
    this.metaKeywordsForm = new FormControl();
    this.ogTitleForm = new FormControl();
    this.ogDescriptionForm = new FormControl();

    this.form = new FormGroup({
      id: this.idForm,
      slug: this.slugForm,
      title: this.titleForm,
      createdAt: this.createdAtForm,
      subtitle: this.subtitleForm,
      content: this.contentForm,
      mainImage: this.mainImageForm,
      metaDescription: this.metaDescriptionForm,
      metaKeywords: this.metaKeywordsForm,
      ogTitle: this.ogTitleForm,
      ogDescription: this.ogDescriptionForm,
    });
  }

  save(): void {
    if (this.form.valid) {
      this.saving = true;

      if (this.blogPostId) {
        this.blogPostsService
          .updateBlogPost(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<BlogPost>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.router.navigateByUrl('/blog-posts');
            }
          });
      } else {
        this.blogPostsService
          .createBlogPost(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<BlogPost>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.router.navigateByUrl('/blog-posts');
            }
          });
      }
    }
  }

  getTinyMceInitData(): any{
    return {
      base_url: '/tinymce',
      suffix: '.min',
      language: 'pl',
      language_url: '/tinymce-i18n/langs/pl.js',
      plugins: ['code', 'link', 'image', 'media', 'lists'],
      default_link_target: '_blank',
      images_upload_handler: (blobInfo, success, failure) => {
        const image = new Image();
        image.base64 = blobInfo.base64();
        const galleryElement = new GalleryElement();
        galleryElement.image = image;
        galleryElement.blogContentPostId = this.blogPostId;
        this.galleryElementsService.createGalleryElement(galleryElement).subscribe((response) => {
          if(response.ok){
            success(response.body?.image?.url);
          } else{
            failure("Błąd podczas wgrywania pliku");
          }
        });
      }
    };

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
