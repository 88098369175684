<!-- * LOADER -->

<div id="basic-global-spinner" class="spinner" *ngIf="loading">
  <div class="blob blob-0"></div>
  <div class="blob blob-1"></div>
  <div class="blob blob-2"></div>
  <div class="blob blob-3"></div>
  <div class="blob blob-4"></div>
  <div class="blob blob-5"></div>
</div>

<!-- * END LOADER -->

<router-outlet></router-outlet>
