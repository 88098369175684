import { DestinationEditDialogComponent } from './../destination-edit-dialog/destination-edit-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({ template: '' })
export class DestinationEditDialogOpenerComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const editingDirectionId = params.id;

      if (editingDirectionId === 'new') {
        const dialogRef = this.dialog.open(DestinationEditDialogComponent, {
          data: { type: 'new' },
          panelClass: 'big-dialog',
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigateByUrl('/destinations');
        });
      } else if (
        typeof editingDirectionId !== 'undefined' &&
        editingDirectionId &&
        editingDirectionId !== ''
      ) {
        const paramData = { type: 'edit', id: editingDirectionId };
        const dialogRef = this.dialog.open(DestinationEditDialogComponent, {
          data: paramData,
          panelClass: 'big-dialog',
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigateByUrl('/destinations');
        });
      }
    });
  }
}
