<mat-card>
  <mat-card-header>
    <mat-card-title> Wyjazdy </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="to-right">
      <button
        mat-raised-button
        routerLink="/tours/edit/new"
        type="button"
        color="primary"
      >
        <mat-icon>add</mat-icon>
        Dodaj nowy wyjazd
      </button>
    </div>

    <div>
      <form class="example-form">
        <mat-form-field class="example-full-width">
          <mat-label>Wyszukaj</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input
            type="text"
            matInput
            placeholder="Nazwa"
            (change)="onSearchChange($event.target.value)"
            [(ngModel)]="searchBy"
            name="searchBy"
          />

          <button
            mat-button
            *ngIf="searchBy"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="value = ''; onSearchChange('')"
            type="button"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
          
        <mat-checkbox
          color="primary"
          (change)="onShowArchivedChange($event.checked)"
          [(ngModel)]="showArchived"
          name="showArchived"
        >
          Pokaż archiwalne wyjazdy
        </mat-checkbox>

      </form>
    </div>

    <div>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="onSortChange($event)"
        [matSortActive]="orderBy"
        [matSortDirection]="orderDir"
        matSortStart="asc"
        matSortDisableClear
      >
        <ng-container matColumnDef="dateStart">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Data wyjazdu
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.dateStart | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="destination">
          <th mat-header-cell *matHeaderCellDef>Kierunek</th>
          <td mat-cell *matCellDef="let element">
            {{ element.destination?.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Typ</th>
          <td mat-cell *matCellDef="let element">
            {{ "type." + element.type | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="kinds">
          <th mat-header-cell *matHeaderCellDef>Rodzaj</th>
          <td mat-cell *matCellDef="let element">
            <span *ngFor="let kind of element.kinds; let isLast = last">
              {{ "kind." + kind | translate }}{{ isLast ? "" : ", " }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="difficulty">
          <th mat-header-cell *matHeaderCellDef>Poziom trudności</th>
          <td mat-cell *matCellDef="let element">
            {{ "difficulty." + element.difficulty | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="to-right">
            <button
              mat-button
              mat-icon-button
              matTooltip="Usuń"
              (click)="delete(element)"
              class="btn-delete"
            >
              <mat-icon>delete</mat-icon>
            </button>

            <a
              mat-icon-button
              routerLink="/tours/edit/new"
              [queryParams]="{ copyOfTour: element.id }"
              matTooltip="Duplikuj"
              color="primary"
            >
              <mat-icon>content_copy</mat-icon>
            </a>

            <a
              mat-icon-button
              [routerLink]="['/tours/gallery', element.id]"
              matTooltip="Edytuj galerię"
              color="primary"
            >
              <mat-icon>photo</mat-icon>
            </a>

            <a
              mat-icon-button
              [routerLink]="['/tours/edit', element.id]"
              matTooltip="Edytuj"
              color="primary"
            >
              <mat-icon>edit</mat-icon>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-spinner *ngIf="loading"></mat-spinner>

      <mat-paginator
        [pageSizeOptions]="[25, 50, 100]"
        [length]="totalItems"
        [pageSize]="perPage"
        [pageIndex]="pageIndex"
        (page)="onPaginateChange($event)"
      >
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<div>
  <button
    mat-fab
    class="mat-fab-bottom-right"
    color="primary"
    routerLink="/tours/edit/new"
    type="button"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>

<router-outlet></router-outlet>
