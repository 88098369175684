import { PageGalleryEditComponent } from './components/page-gallery-edit/page-gallery-edit.component';
import { PagesComponent } from './components/pages/pages.component';
import { PageEditComponent } from './components/pages-edit/page-edit.component';
import { TeamMembersComponent } from './components/team-members/team-members.component';
import { TeamMemberEditComponent } from './components/team-member-edit/team-member-edit.component';
import { TeamMemberDeleteDialogComponent } from './components/team-member-delete-dialog/team-member-delete-dialog.component';
import { BlogPostEditComponent } from './components/blog-post-edit/blog-post-edit.component';
import { BlogPostGalleryEditComponent } from './components/blog-post-gallery-edit/blog-post-gallery-edit.component';
import { BlogPostsComponent } from './components/blog-posts/blog-posts.component';
import { BlogPostDeleteDialogComponent } from './components/blog-post-delete-dialog/blog-post-delete-dialog.component';
import { VideoArrayFormControlComponent } from './components/custom-form-controls/video-array-form-control/video-array-form-control.component';
import { TourGalleryEditComponent } from './components/tour-gallery-edit/tour-gallery-edit.component';
import { InfoSectionArrayFormControlComponent } from './components/custom-form-controls/info-section-array-form-control/info-section-array-form-control.component';
import { SettingsEditDialogOpenerComponent } from './components/settings-edit-dialog-opener/settings-edit-dialog-opener.component';
import { SettingsEditDialogComponent } from './components/settings-edit-dialog/settings-edit-dialog.component';
import { SettingsComponent } from './components/settings/settings.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSortModule } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MainComponent } from './components/main/main.component';
import { MatIconModule } from '@angular/material/icon';
import { DestinationDeleteDialogComponent } from './components/destination-delete-dialog/destination-delete-dialog.component';
import { DestinationEditDialogOpenerComponent } from './components/destination-edit-dialog-opener/destination-edit-dialog-opener.component';
import { DestinationsComponent } from './components/destinations/destinations.component';
import { DestinationEditDialogComponent } from './components/destination-edit-dialog/destination-edit-dialog.component';
import { TourDeleteDialogComponent } from './components/tour-delete-dialog/tour-delete-dialog.component';
import { ToursComponent } from './components/tours/tours.component';
import { TourEditComponent } from './components/tour-edit/tour-edit.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { RoutePointArrayFormControlComponent } from './components/custom-form-controls/route-point-array-form-control/route-point-array-form-control.component';
import { ScheduleElementArrayFormControlComponent } from './components/custom-form-controls/schedule-element-array-form-control/schedule-element-array-form-control.component';
import { MoneyFormControlComponent } from './components/custom-form-controls/money-form-control/money-form-control.component';
import { StringArrayFormControlComponent } from './components/custom-form-controls/string-array-form-control/string-array-form-control.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import '@angular/common/locales/global/pl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { getPolishPaginatorIntl } from './intl/polish-paginator-intl';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ImageFormControlComponent } from './components/custom-form-controls/image-form-control/image-form-control.component';
import { NgpImagePickerModule } from 'ngp-image-picker';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IconComponent } from './components/icon/icon.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NewsletterSubscriberDeleteDialogComponent } from './components/newsletter-subscriber-delete-dialog/newsletter-subscriber-delete-dialog.component';
import { NewsletterSubscribersComponent } from './components/newsletter-subscribers/newsletter-subscribers.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TourEditComponent,
    TourGalleryEditComponent,
    ToursComponent,
    TourDeleteDialogComponent,
    PageEditComponent,
    PageGalleryEditComponent,
    PagesComponent,
    BlogPostGalleryEditComponent,
    BlogPostDeleteDialogComponent,
    BlogPostEditComponent,
    BlogPostsComponent,
    TeamMemberDeleteDialogComponent,
    TeamMemberEditComponent,
    TeamMembersComponent,
    NewsletterSubscriberDeleteDialogComponent,
    NewsletterSubscribersComponent,
    DestinationsComponent,
    DestinationEditDialogComponent,
    DestinationEditDialogOpenerComponent,
    DestinationDeleteDialogComponent,
    SettingsComponent,
    SettingsEditDialogComponent,
    SettingsEditDialogOpenerComponent,
    SidebarComponent,
    HeaderComponent,
    StringArrayFormControlComponent,
    ImageFormControlComponent,
    InfoSectionArrayFormControlComponent,
    MoneyFormControlComponent,
    ScheduleElementArrayFormControlComponent,
    RoutePointArrayFormControlComponent,
    VideoArrayFormControlComponent,
    MainComponent,
    LoginComponent,
    IconComponent,
  ],
  imports: [
    BrowserModule,
    EditorModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatMomentDateModule,
    MatMenuModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatDividerModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatTableModule,
    MatIconModule,
    MatSidenavModule,
    MatSortModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatMenuModule,
    MatListModule,
    NgpImagePickerModule,
    NgxMatSelectSearchModule,
    MatSelectInfiniteScrollModule,
    MatExpansionModule,
    MatTabsModule,
    CommonModule,
    DragDropModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getPolishPaginatorIntl() },
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
