<div
  role="group"
  class="money-form-control-container"
  [attr.aria-labelledby]="_formField?.getLabelId()"
>
  <input
    [(ngModel)]="amount"
    class="money-form-control-input-element money-form-control-input-amount"
    type="number"
    (change)="onDataChange()"
    [disabled]="_disabled"
    #amountInput
  />
  <mat-select
    class="money-form-control-input-element"
    [disabled]="_disabled"
    #currencySelect
    (change)="onDataChange()"
    (selectionChange)="onDataChange()"
    [(ngModel)]="currency"
  >
    <mat-option *ngFor="let code of currenciesCodes" [value]="code">
      {{ code }}
    </mat-option>
  </mat-select>
</div>
