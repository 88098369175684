<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2 [hidden]="loading">
        {{ teamMemberId ? "Edycja wpisu" : "Tworzenie wpisu" }}
      </h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-spinner *ngIf="loading"></mat-spinner>

    <div *ngIf="!loading">
      <form novalidate [formGroup]="form" fxLayout="column" (ngSubmit)="save()">
        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Tytuł</mat-label>
            <input
              matInput
              placeholder="nazwa"
              formControlName="name"
              required
            />
            <mat-error *ngIf="nameForm.errors?.required"
              >To pole nie być puste</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Pozycja</mat-label>
            <input
              matInput
              type="number"
              placeholder="nazwa"
              formControlName="position"
              required
            />
            <mat-error *ngIf="positionForm.errors?.required"
              >To pole nie być puste</mat-error
            >
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Zdjęcie</mat-label>
            <app-image-form-control
              formControlName="photo"
            ></app-image-form-control>
          </mat-form-field>
        </div>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Opis</mat-label>
          <textarea
            formControlName="description"
            matInput
            placeholder="Opis..."
          ></textarea>
        </mat-form-field>
      </form>
    </div>

    <div mat-card-actions>
      <div
        flex
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxFlex="100"
      >
        <a mat-button [routerLink]="'/team-members'"> Anuluj </a>

        <button
          mat-raised-button
          color="primary"
          [class.save-btn-spinner]="saving"
          [disabled]="loading || !form.valid || saving"
          (click)="save()"
        >
          Zapisz
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
