import { Image } from '../../models/image.model';
import { DestinationsService } from '../../services/destinations.service';
import { Destination } from '../../models/destination.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Page } from '../../models/page.model';
import { HttpResponse } from '@angular/common/http';
import { PagesService } from '../../services/pages.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import * as moment from 'moment';

@Component({
  selector: 'app-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss'],
})
export class PageEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  pageId?: string;
  page?: Page;
  loading = true;
  saving = false;
  form: FormGroup;
  titleForm: FormControl;
  subtitleForm: FormControl;
  slugForm: FormControl;
  contentForm: FormControl;
  mainImageForm: FormControl;
  idForm: FormControl;
  metaDescriptionForm: FormControl;
  metaKeywordsForm: FormControl;
  ogTitleForm: FormControl;
  ogDescriptionForm: FormControl;
  searching = false;

  @ViewChild('matSelectInfiniteScroll', { static: true })
  infiniteScrollSelect: MatSelect;

  constructor(
    private pagesService: PagesService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.page = new Page();
    this.createForm();

    this.route.params.subscribe((params) => {
      const editingPageId = params.id;

      if (editingPageId !== 'new') {
        this.loading = true;
        this.pageId = editingPageId;
        this.pagesService
          .findPageById(this.pageId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<Page>) => {
            if (response.ok) {
              this.page = response.body;
              this.form.setValue(this.page);
              this.loading = false;
            }
          });
      } else {
        this.loading = false;
        this.form.setValue(this.page);
      }
    });
  }

  createForm(): void {
    this.idForm = new FormControl(this.pageId);
    this.titleForm = new FormControl();
    this.subtitleForm = new FormControl();
    this.contentForm = new FormControl();
    this.mainImageForm = new FormControl();
    this.metaDescriptionForm = new FormControl();
    this.metaKeywordsForm = new FormControl();
    this.ogTitleForm = new FormControl();
    this.ogDescriptionForm = new FormControl();
    this.slugForm = new FormControl(null, [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9_-]+'),
    ]);

    this.form = new FormGroup({
      id: this.idForm,
      slug: this.slugForm,
      title: this.titleForm,
      subtitle: this.subtitleForm,
      content: this.contentForm,
      mainImage: this.mainImageForm,
      metaDescription: this.metaDescriptionForm,
      metaKeywords: this.metaKeywordsForm,
      ogTitle: this.ogTitleForm,
      ogDescription: this.ogDescriptionForm,
    });
  }

  save(): void {
    if (this.form.valid) {
      this.saving = true;

      if (this.pageId) {
        this.pagesService
          .updatePage(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<Page>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.router.navigateByUrl('/pages');
            }
          });
      } else {
        this.router.navigateByUrl('/pages');
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
