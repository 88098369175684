import { RoutePoint } from '../../../models/route-point.model';
import { Subject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import * as CC from 'currency-codes';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  AfterViewInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormControl,
  FormBuilder,
  NgControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { OnInit } from '@angular/core';

declare var ol: any;

@Component({
  selector: 'app-route-point-array-form-control',
  templateUrl: './route-point-array-form-control.component.html',
  styleUrls: ['./route-point-array-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: RoutePointArrayFormControlComponent,
    },
  ],
  host: {
    '[id]': 'id',
  },
})
export class RoutePointArrayFormControlComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<RoutePoint[]>,
    OnDestroy,
    OnInit,
    AfterViewInit {
  static nextId = 0;
  createForm: FormGroup;
  @ViewChild('mapElement') mapElement: HTMLElement;

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // TODO: disable file upload
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): RoutePoint[] | null {
    return this.items;
  }
  set value(routePoints: RoutePoint[] | null) {
    this.items = routePoints || [];
    this.reloadMarkers();
  }

  reloadMarkers(): void {
    if (!this.map) {
      return;
    }

    if (this.mapLayer) {
      this.map.removeLayer(this.mapLayer);
    }

    let features = [];

    let styles = [
      new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: 'blue',
          width: 3,
        }),
        fill: new ol.style.Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      }),
      new ol.style.Style({
        image: new ol.style.Circle({
          radius: 10,
          stroke: new ol.style.Stroke({
            color: 'red',
            width: 2,
          }),
          fill: new ol.style.Fill({
            color: 'orange',
          }),
        }),
      }),
    ];

    this.items.forEach((routePoint) => {
      features.push(
        new ol.Feature({
          geometry: new ol.geom.Point(
            ol.proj.fromLonLat([routePoint.long, routePoint.lat])
          ),
        })
      );
    });

    this.mapLayer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features,
      }),
      style: styles,
    });

    this.map.addLayer(this.mapLayer);
  }

  get errorState(): boolean {
    return false; //this.items.url === '' && this.required;
  }

  get empty(): boolean {
    return false; //this.items.url === '';
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  items: RoutePoint[] = [];

  id = `app-route-point-array-input-${RoutePointArrayFormControlComponent.nextId++}`;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'app-route-point-array-input';

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit(): void {
    this.map = new ol.Map({
      target: 'map',
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM(),
        }),
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat([10, 25]),
        zoom: 0,
      }),
    });
  }

  map: any;
  mapLayer: any;
  mapFirstTime = true;

  ngAfterViewInit(): void {
    this.map.on('postrender', (event) => {
      if (this.mapFirstTime) {
        this.reloadMarkers();
      }
      this.mapFirstTime = false;
    });
    this.map.on('singleclick', (event) => {
      const lonLat = ol.proj.toLonLat(event.coordinate);
      if (this.map.hasFeatureAtPixel(event.pixel)) {
        this.removeNearest(lonLat[1], lonLat[0]);
      } else {
        this.items.push(new RoutePoint(lonLat[1], lonLat[0]));
        this.onChange(this.items);
      }
      this.reloadMarkers();
    });

    this.reloadMarkers();
  }

  removeNearest(lat, long): void {
    let nearest: RoutePoint = null;
    let nearestDistance = 9999999999;
    this.items.forEach((point) => {
      const distance =
        (+point.lat - lat) * (+point.lat - lat) +
        (+point.long - long) * (+point.long - long);
      if (distance < nearestDistance) {
        nearestDistance = distance;
        nearest = point;
      }
    });

    this.items = this.items.filter((obj) => obj !== nearest);
    this.onChange(this.items);
  }

  writeValue(routePoints: RoutePoint[] | null): void {
    this.items = routePoints || [];
    this.reloadMarkers();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onContainerClick(): void {}

  onChange = (_: any) => {};

  onTouched = () => {};

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.route-point-array-form-control-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  clear(): void {
    this.items = [];
    this.reloadMarkers();
    this.onChange(this.items);
  }
}
