import { element } from 'protractor';
import { Image } from '../../models/image.model';
import { GalleryElementsService } from '../../services/gallery-elements.service';
import { GalleryElement } from '../../models/gallery-element.model';
import { DestinationsService } from '../../services/destinations.service';
import { Destination } from '../../models/destination.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Page } from '../../models/page.model';
import { HttpResponse } from '@angular/common/http';
import { PagesService } from '../../services/pages.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-page-gallery-edit',
  templateUrl: './page-gallery-edit.component.html',
  styleUrls: ['./page-gallery-edit.component.scss'],
})
export class PageGalleryEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  pageId?: string;
  page?: Page;
  loading = true;
  saving = false;
  galleryElements: GalleryElement[] = [];
  newGalleryElements: GalleryElement[] = [];
  updatedGalleryElements: GalleryElement[] = [];
  destinationsFilteringForm: FormControl;
  destinationsFilteringFormOpened = false;
  destinationsFiltered: Destination[] = [];
  destinationsFilteredPage = 1;
  destinationsFilteredLastPage = 1;
  maxPosition = 0;

  @ViewChild('matSelectInfiniteScroll', { static: true })
  infiniteScrollSelect: MatSelect;

  constructor(
    private destinationsService: DestinationsService,
    private galleryElementService: GalleryElementsService,
    private pagesService: PagesService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.page = new Page();

    this.galleryElementService.writeOp$.subscribe((op) => {
      this.galleryElementService
        .findGalleryElementsForPage(this.page)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: HttpResponse<GalleryElement[]>) => {
          if (response.ok) {
            this.galleryElements = response.body;
          }
          this.loading = false;
        });
    });

    this.route.params.subscribe((params) => {
      const editingPageId = params.id;
      this.loading = true;
      this.pageId = editingPageId;
      this.pagesService
        .findPageById(this.pageId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: HttpResponse<Page>) => {
          if (response.ok) {
            this.page = response.body;
            this.galleryElementService
              .findGalleryElementsForPage(this.page)
              .pipe(takeUntil(this.destroy$))
              .subscribe((response: HttpResponse<GalleryElement[]>) => {
                if (response.ok) {
                  this.galleryElements = response.body;
                  this.maxPosition = parseInt(response.headers.get('x-total'));
                }
                this.loading = false;
              });
          }
        });
    });
  }

  save(): void {
    this.saving = true;
    let counter = this.newGalleryElements.length;

    if (
      this.newGalleryElements.length === 0 &&
      this.updatedGalleryElements.length === 0
    ) {
      this.saving = false;
      this.newGalleryElements = [];
      this.updatedGalleryElements = [];
      this.snackbar.open('Zapisano', null, {
        duration: 1500,
        panelClass: 'success-snackbar',
      });
      //this.router.navigateByUrl('/pages');
    }

    if (this.newGalleryElements.length === 0) {
      let updatedCounter = this.updatedGalleryElements.length;
      this.updatedGalleryElements.forEach((element) => {
        this.galleryElementService
          .updateGalleryElement(element)
          .subscribe(() => {
            updatedCounter--;
            if (updatedCounter <= 0) {
              this.saving = false;
              this.newGalleryElements = [];
              this.updatedGalleryElements = [];
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              //this.router.navigateByUrl('/pages');
            }
          });
      });
    }

    this.newGalleryElements.forEach((element) => {
      this.galleryElementService.createGalleryElement(element).subscribe(() => {
        counter--;
        if (counter <= 0) {
          let updatedCounter = this.updatedGalleryElements.length;
          this.updatedGalleryElements.forEach((element) => {
            this.galleryElementService
              .updateGalleryElement(element)
              .subscribe(() => {
                updatedCounter--;
                if (updatedCounter <= 0) {
                  this.saving = false;
                  this.newGalleryElements = [];
                  this.updatedGalleryElements = [];
                  this.snackbar.open('Zapisano', null, {
                    duration: 1500,
                    panelClass: 'success-snackbar',
                  });
                  //this.router.navigateByUrl('/pages');
                }
              });
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateGalleryElement(element: GalleryElement): void {
    if (!element.id) {
      return;
    }

    const existing = this.updatedGalleryElements.find(
      (x) => x.id == element.id
    );

    if (existing) {
      return;
    }

    this.updatedGalleryElements.push(element);
  }

  deleteGalleryElement(elment: GalleryElement): void {
    this.loading = true;
    this.galleryElementService
      .deleteGalleryElement(elment)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<GalleryElement>) => {
        if (response.ok) {
          this.snackbar.open('Usunięto', null, {
            duration: 2000,
            panelClass: 'success-snackbar',
          });
        }
      });
  }

  galleryFilesUpload(event): void {
    for (const file of event.target.files) {
      const reader = new FileReader();
      reader.onloadstart = () => {
        event.target.disabled = 'disabled';
      };

      reader.onload = () => {
        event.target.disabled = '';
        const newImage = new Image();
        const resString = reader.result.toString();
        const parts = resString.split(',', 2);
        const mimeParts = parts[0].split(';')[0].split(':');
        newImage.base64 = parts[1];
        newImage.mime = mimeParts[1];
        const newGalleryElement = new GalleryElement();
        newGalleryElement.image = newImage;
        newGalleryElement.pageId = this.page.id;
        newGalleryElement.position = this.maxPosition + 1;
        this.galleryElements.push(newGalleryElement);
        this.newGalleryElements.push(newGalleryElement);
      };
      reader.onerror = (error) => {
        event.target.disabled = '';
        console.error('Błąd podczas wgrywania plików: ', error);
      };
      reader.readAsDataURL(file);
    }
  }
}
