import { Image } from './image.model';
import { Tour } from './tour.model';
export interface GalleryElement {
  id?: string;
  tourId?: string;
  pageId?: string;
  blogPostId?: string;
  blogContentPostId?: string;
  image?: Image;
  position: number;
}

export class GalleryElement implements GalleryElement {
  constructor() {
    this.image = null;
    this.id = null;
    this.tourId = null;
    this.pageId = null;
    this.blogPostId = null;
    this.blogContentPostId = null;
    this.position = 0;
  }
}
