<div
  role="group"
  class="image-form-control-container"
  [attr.aria-labelledby]="_formField?.getLabelId()"
>
  <img
    [hidden]="!currentImage || !currentImage?.base64 || !currentImage?.mime"
    class="previewImage"
    [src]="
      currentImage?.mime && currentImage?.base64
        ? 'data:' + currentImage?.mime + ';base64,' + currentImage?.base64
        : ''
    "
    #imageBase64
    (load)="previewLoaded($event)"
  />
  <img
    *ngIf="currentImage && currentImage?.url"
    class="previewImage"
    [src]="currentImage?.url"
  />

  <input
    #fileInput
    hidden
    class="ng-hide"
    id="input-file-id"
    type="file"
    (change)="fileChange($event)"
    accept="image/*"
  />
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-icon-button color="warn" type="button" (click)="clear()">
      <mat-icon>delete</mat-icon>
    </button>
    <button
      type="button"
      mat-raised-button
      (click)="fileInput.click()"
      color="primary"
    >
      <mat-icon>add_a_photo</mat-icon>
      Wgraj
    </button>
  </div>
</div>
