import { Component } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loading: boolean = true;
  firstGlobalSpinner: HTMLElement;

  constructor(private router: Router) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
    this.firstGlobalSpinner = document.getElementById(
      'first-global-spinner'
    ) as HTMLElement;
  }

  checkRouterEvent(routerEvent: Event): void {
    // if (routerEvent instanceof NavigationStart) {
    //     this.loading = true;
    // }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  ngOnInit() {
    this.firstGlobalSpinner.style.display = 'none';
  }
}
