export interface RoutePoint {
  id?: string;
  lat: string;
  long: string;
}

export class RoutePoint implements RoutePoint {
  id?: string;

  constructor(public lat: string = '', public long: string = '') {
    this.id = null;
  }
}
