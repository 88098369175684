import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NewsletterSubscriber } from '../../models/newsletter-subscriber.model';
import { HttpResponse } from '@angular/common/http';
import { NewsletterSubscribersService } from '../../services/newsletter-subscribers.service';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-newsletterSubscriber-delete-dialog',
  templateUrl: './newsletter-subscriber-delete-dialog.component.html',
  styleUrls: ['./newsletter-subscriber-delete-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsletterSubscriberDeleteDialogComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  newsletterSubscriberId?: string;
  newsletterSubscriber?: NewsletterSubscriber;
  loading = true;
  deleting = false;

  constructor(
    private dialogRef: MatDialogRef<NewsletterSubscriberDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { id?: string },
    private newsletterSubscribersService: NewsletterSubscribersService,
    private snackbar: MatSnackBar
  ) {
    this.newsletterSubscriberId = data.id;
  }

  ngOnInit(): void {
    this.newsletterSubscribersService
      .findNewsletterSubscriberById(this.newsletterSubscriberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<NewsletterSubscriber>) => {
        if (response.ok) {
          this.newsletterSubscriber = response.body;
        }
        this.loading = false;
      });
  }

  delete(): void {
    this.deleting = true;

    this.newsletterSubscribersService
      .deleteNewsletterSubscriber(this.newsletterSubscriber)
      .pipe(finalize(() => (this.deleting = false)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<NewsletterSubscriber[]>) => {
        if (response.ok) {
          this.snackbar.open('Usunięto', null, {
            duration: 1500,
            panelClass: 'success-snackbar',
          });
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
