import { Icon } from './../../../models/icon.model';
import { HttpResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { IconsService } from './../../../services/icons.service';
import { TourInfoSection } from './../../../models/tour-info-section.model';
import { Subject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import * as CC from 'currency-codes';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  AfterViewInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormControl,
  FormBuilder,
  NgControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-info-section-array-form-control',
  templateUrl: './info-section-array-form-control.component.html',
  styleUrls: ['./info-section-array-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InfoSectionArrayFormControlComponent,
    },
  ],
  host: {
    '[id]': 'id',
  },
})
export class InfoSectionArrayFormControlComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<TourInfoSection[]>,
    OnDestroy {
  static nextId = 0;
  openSection = -1;

  setOpenSection(num: number) {
    this.openSection = num;

    if (this.items[num].icon) {
      this.iconsFiltered = [this.items[num].icon];
    } else {
      this.iconsFiltered = [];
    }
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // TODO: disable file upload
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): TourInfoSection[] | null {
    return this.items;
  }
  set value(sections: TourInfoSection[] | null) {
    sections = sections || [];
    sections.forEach((section) => {
      if (section.icon) {
        this.iconsFiltered.push(section.icon);
      }
    });
    this.items = sections;
  }

  get errorState(): boolean {
    return false; //this.items.url === '' && this.required;
  }

  get empty(): boolean {
    return false; //this.items.url === '';
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  items: TourInfoSection[] = [];

  id = `app-info-section-array-input-${InfoSectionArrayFormControlComponent.nextId++}`;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'app-info-section-array-input';

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private iconsService: IconsService,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(sections: TourInfoSection[] | null): void {
    this.items = sections || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }
  destroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.destroy$.next();
    this.destroy$.complete();
  }

  onContainerClick(): void {}

  onChange = (_: any) => {};

  onTouched = () => {};

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.info-section-array-form-control-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onInfoSectionArrayChange(): void {
    this.onChange(this.items);
  }

  deleteSection(item: TourInfoSection): void {
    this.items = this.items.filter((obj) => obj !== item);
    this.onChange(this.items);
  }

  iconsSearchPhrase = '';

  getMoreIcon(): void {
    this.iconsFilteredPage += 1;
    this.searching = true;
    this.iconsService
      .findIcons('name', 'asc', this.iconsFilteredPage, 10, [
        {
          filterName: 'filter[name][phrase]',
          filterValue: this.iconsSearchPhrase,
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<Icon[]>) => {
        if (response.ok) {
          this.iconsFiltered = [...this.iconsFiltered, ...response.body];
        }
        this.searching = false;
      });
  }

  compareIcons(o1: any, o2: any): boolean {
    return (
      typeof o1 === typeof o2 &&
      ((o1 === null && o2 === null) || (o1 !== null && o2 !== null)) &&
      o1.type === o2.type &&
      o1.fontClasses === o2.fontClasses &&
      o1.url === o2.url
    );
  }
  iconsFilteredPage = 1;
  iconsFilteredLastPage = 1;
  iconsFilteringFormOpened = false;

  iconFormOpenedChange(opened: boolean): void {
    this.iconsFilteringFormOpened = opened;
  }
  searching = false;
  iconsFiltered: Icon[] = [];

  onIconsSearch(value: string): void {
    if (!this.iconsFilteringFormOpened) {
      return;
    }

    this.searching = true;
    this.iconsService
      .findIcons('name', 'asc', 1, 10, [
        { filterName: 'filter[name][phrase]', filterValue: value },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<Icon[]>) => {
        if (response.ok) {
          this.iconsFiltered = response.body;
        }
        this.iconsFilteredPage = 1;
        const lastPageHeader = response.headers.get('x-last-page');
        this.iconsFilteredLastPage = parseInt(lastPageHeader, 10);

        this.searching = false;
      });
  }

  addSection(): void {
    this.openSection = this.items.push(new TourInfoSection('Nowa sekcja')) - 1;
    this.onChange(this.items);
  }

  drop(event: CdkDragDrop<TourInfoSection[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.onChange(this.items);
  }
}
