import { Image } from '../../models/image.model';
import { DestinationsService } from '../../services/destinations.service';
import { Destination } from '../../models/destination.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TeamMember } from '../../models/team-member.model';
import { HttpResponse } from '@angular/common/http';
import { TeamMembersService } from '../../services/team-members.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import * as moment from 'moment';

@Component({
  selector: 'app-team-member-edit',
  templateUrl: './team-member-edit.component.html',
  styleUrls: ['./team-member-edit.component.scss'],
})
export class TeamMemberEditComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  teamMemberId?: string;
  teamMember?: TeamMember;
  loading = true;
  saving = false;
  form: FormGroup;
  nameForm: FormControl;
  descriptionForm: FormControl;
  photoForm: FormControl;
  idForm: FormControl;
  positionForm: FormControl;
  searching = false;

  @ViewChild('matSelectInfiniteScroll', { static: true })
  infiniteScrollSelect: MatSelect;

  constructor(
    private teamMembersService: TeamMembersService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.teamMember = new TeamMember();
    this.createForm();

    this.route.params.subscribe((params) => {
      const editingTeamMemberId = params.id;

      if (editingTeamMemberId !== 'new') {
        this.loading = true;
        this.teamMemberId = editingTeamMemberId;
        this.teamMembersService
          .findTeamMemberById(this.teamMemberId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<TeamMember>) => {
            if (response.ok) {
              this.teamMember = response.body;
              this.form.setValue(this.teamMember);
              this.loading = false;
            }
          });
      } else {
        this.loading = false;
        this.form.setValue(this.teamMember);
      }
    });
  }

  createForm(): void {
    this.nameForm = new FormControl(null, Validators.required);
    this.idForm = new FormControl(this.teamMemberId);
    this.nameForm = new FormControl();
    this.descriptionForm = new FormControl();
    this.photoForm = new FormControl();
    this.positionForm = new FormControl();

    this.form = new FormGroup({
      id: this.idForm,
      name: this.nameForm,
      description: this.descriptionForm,
      photo: this.photoForm,
      position: this.positionForm,
    });
  }

  save(): void {
    if (this.form.valid) {
      this.saving = true;

      if (this.teamMemberId) {
        this.teamMembersService
          .updateTeamMember(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<TeamMember>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.router.navigateByUrl('/team-members');
            }
          });
      } else {
        this.teamMembersService
          .createTeamMember(this.form.value)
          .pipe(finalize(() => (this.saving = false)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: HttpResponse<TeamMember>) => {
            if (response.ok) {
              this.snackbar.open('Zapisano', null, {
                duration: 1500,
                panelClass: 'success-snackbar',
              });
              this.router.navigateByUrl('/team-members');
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
