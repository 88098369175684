<div
  role="group"
  class="string-array-form-control-container"
  [attr.aria-labelledby]="_formField?.getLabelId()"
>
  <div>
    <form
      (submit)="
        onAddElementSubmit($event.target.element.value);
        $event.target.element.value = ''
      "
    >
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Dodaj do listy</mat-label>
        <input
          #createInput
          name="element"
          matInput
          placeholder="Nowy elment..."
        />
        <button matSuffix mat-icon-button type="submit">
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>

  <div cdkDropList class="items-list" (cdkDropListDropped)="drop($event)">
    <div
      class="items-box"
      *ngFor="let item of items; index as i"
      cdkDrag
      [cdkDragData]="item"
    >
      {{ item }}
      <button mat-icon-button (click)="deleteItem(i)" type="button">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div class="items-box" *ngIf="items.length === 0">
      <i>(brak elementów)</i>
    </div>
  </div>
</div>
