import { AuthService } from './auth.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Icon } from '../models/icon.model';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
