import { PageGalleryEditComponent } from './components/page-gallery-edit/page-gallery-edit.component';
import { PageEditComponent } from './components/pages-edit/page-edit.component';
import { PagesComponent } from './components/pages/pages.component';
import { TeamMembersComponent } from './components/team-members/team-members.component';
import { BlogPostEditComponent } from './components/blog-post-edit/blog-post-edit.component';
import { BlogPostGalleryEditComponent } from './components/blog-post-gallery-edit/blog-post-gallery-edit.component';
import { BlogPostsComponent } from './components/blog-posts/blog-posts.component';
import { TourGalleryEditComponent } from './components/tour-gallery-edit/tour-gallery-edit.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginComponent } from './components/login/login.component';
import { SettingsEditDialogOpenerComponent } from './components/settings-edit-dialog-opener/settings-edit-dialog-opener.component';
import { MainComponent } from './components/main/main.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DestinationEditDialogOpenerComponent } from './components/destination-edit-dialog-opener/destination-edit-dialog-opener.component';
import { DestinationsComponent } from './components/destinations/destinations.component';
import { ToursComponent } from './components/tours/tours.component';
import { TourEditComponent } from './components/tour-edit/tour-edit.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamMemberEditComponent } from './components/team-member-edit/team-member-edit.component';
import { NewsletterSubscribersComponent } from './components/newsletter-subscribers/newsletter-subscribers.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'tours/edit/:id',
        component: TourEditComponent,
      },
      {
        path: 'tours/gallery/:id',
        component: TourGalleryEditComponent,
      },
      {
        path: 'tours',
        component: ToursComponent,
      },
      {
        path: 'blog-posts/edit/:id',
        component: BlogPostEditComponent,
      },
      {
        path: 'blog-posts/gallery/:id',
        component: BlogPostGalleryEditComponent,
      },
      {
        path: 'blog-posts',
        component: BlogPostsComponent,
      },
      {
        path: 'team-members/edit/:id',
        component: TeamMemberEditComponent,
      },
      {
        path: 'team-members',
        component: TeamMembersComponent,
      },
      {
        path: 'newsletter-subscribers',
        component: NewsletterSubscribersComponent,
      },
      {
        path: 'pages/edit/:id',
        component: PageEditComponent,
      },
      {
        path: 'pages/gallery/:id',
        component: PageGalleryEditComponent,
      },
      {
        path: 'pages',
        component: PagesComponent,
      },
      {
        path: 'destinations',
        component: DestinationsComponent,
        children: [
          {
            path: ':id',
            component: DestinationEditDialogOpenerComponent,
          },
        ],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          {
            path: ':id',
            component: SettingsEditDialogOpenerComponent,
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
