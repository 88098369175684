import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Icon } from '../models/icon.model';
import { Observable, Subject, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable, Injector } from '@angular/core';
import { finalize, takeUntil, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private API_ENDPOINT = '/auth';

  private token = '';

  public doLogout = new Subject<void>();

  constructor(private router: Router) {
    this.token = localStorage.getItem('api-auth-token');
  }

  public isAuthenticated(): boolean {
    return !!this.token;
  }

  public logout(): void {
    localStorage.setItem('api-auth-token', '');
    this.token = '';
    this.router.navigate(['login']);
  }

  public authHeader(): string {
    return 'Bearer ' + this.token;
  }

  public login(token: string): void {
    this.token = token;
    localStorage.setItem('api-auth-token', this.token);
  }
}
