<mat-card>
  <mat-card-header>
    <mat-card-title> Blog - wpisy </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="to-right">
      <button
        mat-raised-button
        routerLink="/blog-posts/edit/new"
        type="button"
        color="primary"
      >
        <mat-icon>add</mat-icon>
        Dodaj nowy wpis
      </button>
    </div>

    <div>
      <form class="example-form">
        <mat-form-field class="example-full-width">
          <mat-label>Wyszukaj</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input
            type="text"
            matInput
            placeholder="Nazwa"
            (change)="onSearchChange($event.target.value)"
            [(ngModel)]="searchBy"
            name="searchBy"
          />

          <button
            mat-button
            *ngIf="searchBy"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="value = ''; onSearchChange('')"
            type="button"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>

    <div>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="onSortChange($event)"
        [matSortActive]="orderBy"
        [matSortDirection]="orderDir"
        matSortStart="asc"
        matSortDisableClear
      >
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tytuł</th>
          <td mat-cell *matCellDef="let element">
            {{ element.title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="subtitle">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Podtytuł</th>
          <td mat-cell *matCellDef="let element">
            {{ element.subtitle }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data wpisu</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="to-right">
            <button
              mat-button
              mat-icon-button
              matTooltip="Usuń"
              (click)="delete(element)"
              class="btn-delete"
            >
              <mat-icon>delete</mat-icon>
            </button>

            <a
              mat-icon-button
              [routerLink]="['/blog-posts/gallery', element.id]"
              matTooltip="Edytuj galerię"
              color="primary"
            >
              <mat-icon>photo</mat-icon>
            </a>

            <a
              mat-icon-button
              [routerLink]="['/blog-posts/edit', element.id]"
              matTooltip="Edytuj"
              color="primary"
            >
              <mat-icon>edit</mat-icon>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-spinner *ngIf="loading"></mat-spinner>

      <mat-paginator
        [pageSizeOptions]="[25, 50, 100]"
        [length]="totalItems"
        [pageSize]="perPage"
        [pageIndex]="pageIndex"
        (page)="onPaginateChange($event)"
      >
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<div>
  <button
    mat-fab
    class="mat-fab-bottom-right"
    color="primary"
    routerLink="/blog-posts/edit/new"
    type="button"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>

<router-outlet></router-outlet>
