import { NewsletterSubscriberDeleteDialogComponent } from '../newsletter-subscriber-delete-dialog/newsletter-subscriber-delete-dialog.component';
import { HttpResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NewsletterSubscribersService } from '../../services/newsletter-subscribers.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { NewsletterSubscriber } from 'src/app/models/newsletter-subscriber.model';
import { Subject } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, Event } from '@angular/router';

@Component({
  selector: 'app-newsletter-subscribers',
  templateUrl: './newsletter-subscribers.component.html',
  styleUrls: ['./newsletter-subscribers.component.scss'],
})
export class NewsletterSubscribersComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  dataSource: MatTableDataSource<NewsletterSubscriber>;
  displayedColumns: string[] = ['email', 'createdAt', 'actions'];

  loading = true;
  pageIndex = 0;
  perPage = 25;
  totalItems = 0;
  orderBy = 'createdAt';
  orderDir = 'desc';
  searchBy = '';

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private newsletterSubscribersService: NewsletterSubscribersService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.newsletterSubscribersService.writeOp$.subscribe((writeOp) => {
      this.loadNewsletterSubscribers();
    });

    this.dataSource = new MatTableDataSource();
    this.loadNewsletterSubscribers();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.dataSource.disconnect();
    this.dataSource.data = [];
  }

  loadNewsletterSubscribers(): void {
    this.loading = true;
    this.dataSource.data = [];

    this.newsletterSubscribersService
      .findNewsletterSubscribers(
        this.orderBy,
        this.orderDir,
        this.pageIndex + 1,
        this.perPage,
        []
      )
      .pipe(finalize(() => (this.loading = false)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<NewsletterSubscriber[]>) => {
        const totalHeader = response.headers.get('x-total');
        const pageHeader = response.headers.get('x-page');
        const perPageHeader = response.headers.get('x-per-page');
        this.totalItems = parseInt(totalHeader, 10);
        this.perPage = parseInt(perPageHeader, 10);
        this.pageIndex = parseInt(pageHeader, 10) - 1;
        if (response.ok) {
          this.dataSource.data = response.body;
        }
      });
  }

  onSortChange(event: Sort): void {
    this.orderBy = event.active;
    this.orderDir = event.direction;
    this.pageIndex = 0;
    this.loadNewsletterSubscribers();
  }

  onPaginateChange(event: PageEvent): void {
    this.perPage = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadNewsletterSubscribers();
  }

  delete(newsletterSubscriber: NewsletterSubscriber): void {
    const dialogRef = this.dialog.open(NewsletterSubscriberDeleteDialogComponent, {
      data: { id: newsletterSubscriber.id },
    });
    dialogRef.disableClose = true;
  }
}
