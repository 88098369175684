import { Money } from './money.model';
import { Image } from './image.model';
import { Destination } from './destination.model';
import { RoutePoint } from './route-point.model';
import { ScheduleElement } from './schedule-element.model';
import { TourInfoSection } from './tour-info-section.model';
import { Video } from './video.model';

export interface Tour {
  id?: string;
  copyOf?: string;
  shortName?: string;
  metaDescription?: string;
  metaKeywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  slug: string;
  name: string;
  skaleoButtonCode: string;
  description: string;
  type: '' | 'tramping' | 'premium';
  kinds: (
    | 'motorcycle'
    | 'diving'
    | 'surfing'
    | 'kitesurfing-windsurfing'
    | 'via-ferrata'
    | 'skitouring'
    | 'cooking'
    | 'skiing'
    | 'joga'
    | 'nature'
    | 'road-trip'
    | 'relax'
    | 'culture'
    | 'family'
    | 'trekking'
    | 'photoshooting'
  )[];
  difficulty: '' | 'easy' | 'medium' | 'hard' | 'extreme';
  basePrice?: Money;
  additionalPrice?: Money;
  photo?: Image;
  destination?: Destination;
  participantsLimit?: string;
  dateStart?: string;
  duration?: number;
  pros: string[];
  priceInclude: string[];
  priceExclude: string[];
  routePoints: RoutePoint[];
  routeDescription: string;
  schedule: ScheduleElement[];
  infoSections: TourInfoSection[];
  videos?: Video[];
}

export class Tour implements Tour {
  constructor() {
    this.id = null;
    this.name = '';
    this.slug = '';
    this.shortName = '';
    this.description = '';
    this.type = '';
    this.kinds = [];
    this.difficulty = '';
    this.basePrice = null;
    this.additionalPrice = null;
    this.photo = null;
    this.destination = null;
    this.participantsLimit = null;
    this.dateStart = null;
    this.duration = null;
    this.pros = [];
    this.priceInclude = [];
    this.priceExclude = [];
    this.routePoints = [];
    this.routeDescription = '';
    this.schedule = [];
    this.infoSections = [];
    this.videos = [];
    this.copyOf = null;
    this.metaDescription = '';
    this.metaKeywords = '';
    this.ogTitle = '';
    this.ogDescription = '';
    this.skaleoButtonCode = '';
  }
}
