import { Icon } from './icon.model';

export interface TourInfoSection {
  id?: string;
  title: string;
  content: string;
  icon?: Icon;
}

export class TourInfoSection implements TourInfoSection {
  id?: string;
  icon?: Icon;

  constructor(public title: string = '', public content: string = '') {
    this.id = null;
    this.icon = null;
  }
}
