import { Subject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import * as CC from 'currency-codes';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  AfterViewInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormControl,
  FormBuilder,
  NgControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from '@angular/material/form-field';
import { Image } from 'src/app/models/image.model';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgpImagePickerComponent, ImagePickerConf } from 'ngp-image-picker';

@Component({
  selector: 'app-image-form-control',
  templateUrl: './image-form-control.component.html',
  styleUrls: ['./image-form-control.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: ImageFormControlComponent },
  ],
  host: {
    '[id]': 'id',
  },
})
export class ImageFormControlComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<Image>,
    OnDestroy,
    AfterViewInit {
  static nextId = 0;
  @ViewChild('imageBase64') imageBase64: HTMLImageElement;

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // TODO: disable file upload
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Image | null {
    return this.currentImage;
  }
  set value(image: Image | null) {
    image = image || new Image();
    this.currentImage = image;
  }

  currentImage?: Image;

  get errorState(): boolean {
    return false; //this.currentImage.url === '' && this.required;
  }

  get empty(): boolean {
    return false; //this.currentImage.url === '';
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  id = `app-image-input-${ImageFormControlComponent.nextId++}`;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'app-image-input';
  imagePickerConf: ImagePickerConf = {
    borderRadius: '4px',
    language: 'en',
    width: '320px',
    height: '240px',
  };

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    //this.imagePicker.imageSrc = this.currentImage.url;
  }

  writeValue(image: Image | null): void {
    this.currentImage = image;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onContainerClick(): void {}

  onChange = (_: any) => {};

  onTouched = () => {};

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.image-form-control-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onImageChange(event): void {}

  clear(): void {
    this.currentImage = null;
    this.onChange(this.currentImage);
  }

  fileChange(event): void {
    const reader = new FileReader();
    reader.onloadstart = () => {
      event.target.disabled = 'disabled';
    };

    reader.onload = () => {
      event.target.disabled = '';
      this.currentImage = new Image();
      const resString = reader.result.toString();
      const parts = resString.split(',', 2);
      const mimeParts = parts[0].split(';')[0].split(':');
      this.currentImage.base64 = parts[1];
      this.currentImage.mime = mimeParts[1];
      this.onChange(this.currentImage);
    };
    reader.onerror = (error) => {
      event.target.disabled = '';
      console.error('Upload error: ', error);
      this.currentImage = null;
      this.onChange(this.currentImage);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  previewLoaded(event): void {
    this.currentImage.w = event.target.naturalWidth;
    this.currentImage.h = event.target.naturalHeight;
    this.onChange(this.currentImage);
  }
}
