export interface ScheduleElement {
  id?: string;
  dayStart: number;
  dayEnd: number;
  description: string;
}

export class ScheduleElement implements ScheduleElement {
  constructor(
    public dayStart: number = 1,
    public dayEnd: number = 1,
    public description: string = ''
  ) {
    this.id = null;
  }
}
