import { catchError } from 'rxjs/operators';
import { ApiService } from './../../services/api.service';
import { Subject, throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  public loginInvalid: boolean;
  waiting = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router
  ) {}

  async ngOnInit() {
    if (this.authService.isAuthenticated) {
      this.router.navigate(['']);
    }

    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.loginInvalid = false;
    if (this.form.valid) {
      this.waiting = true;

      const email = this.form.get('email').value;
      const password = this.form.get('password').value;

      this.apiService
        .rawPostRequest(
          '/auth',
          {
            email,
            password,
          },
          {
            observe: 'response',
            headers: null,
            params: null,
            reportProgress: null,
            responseType: 'json',
            withCredentials: null,
          }
        )
        .pipe(
          catchError(
            (error: HttpErrorResponse): Observable<never> => {
              this.waiting = false;
              this.loginInvalid = true;
              return throwError(error);
            }
          )
        )
        .subscribe((response: HttpResponse<{ token: string }>) => {
          if (response.ok) {
            this.authService.login(response.body.token);
            this.waiting = false;
            this.router.navigate(['']);
          }
        });
    }
  }
}
