<mat-nav-list>
  <h2 matSubheader>Zarządzanie</h2>

  <a mat-list-item routerLinkActive="list-link-active" routerLink="/tours">
    <mat-icon>flight</mat-icon> Wyjazdy
  </a>

  <a mat-list-item routerLinkActive="list-link-active" routerLink="/blog-posts">
    <mat-icon>book</mat-icon> Blog
  </a>

  <a
    mat-list-item
    routerLinkActive="list-link-active"
    routerLink="/team-members"
  >
    <mat-icon>people</mat-icon> Zespół
  </a>

  <a mat-list-item routerLinkActive="list-link-active" routerLink="/pages">
    <mat-icon>public</mat-icon> Strony
  </a>
  <a
    mat-list-item
    routerLinkActive="list-link-active"
    routerLink="/destinations"
  >
    <mat-icon>swap_vert</mat-icon> Kierunki wyjazdów
  </a>

  <mat-divider></mat-divider>

  <a
    mat-list-item
    routerLinkActive="list-link-active"
    routerLink="/newsletter-subscribers"
  >
    <mat-icon>email</mat-icon> Newsletter
  </a>

  <mat-divider></mat-divider>

  <a mat-list-item routerLinkActive="list-link-active" routerLink="/settings">
    <mat-icon>settings</mat-icon> Ustawienia
  </a>
</mat-nav-list>
