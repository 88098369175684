<div
  role="group"
  class="info-section-array-form-control-container"
  [attr.aria-labelledby]="_formField?.getLabelId()"
>
  <div class="to-right">
    <button mat-button color="primary" type="button" (click)="addSection()">
      <mat-icon>add</mat-icon> Dodaj sekcję
    </button>
  </div>

  <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-expansion-panel
      cdkDrag
      [cdkDragData]="item"
      [expanded]="openSection === i"
      (opened)="setOpenSection(i)"
      *ngFor="let item of items; index as i"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <app-icon [icon]="item.icon"></app-icon>
          {{ item.title }}
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        fxLayout="row"
        fxLayout.lt-lg="column"
        fxLayoutGap="20px"
        fxLayoutGap.lt-lg="0px"
      >
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Tytuł</mat-label>
          <input matInput [(ngModel)]="item.title" />
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Ikona</mat-label>
          <mat-select
            #matSelectInfiniteScroll
            msInfiniteScroll
            (infiniteScroll)="getMoreIcon()"
            [compareWith]="compareIcons"
            [(ngModel)]="item.icon"
            placeholder="Wpisz nazwę aby wyszukać"
            [complete]="iconsFilteredLastPage === iconsFilteredPage"
            (openedChange)="iconFormOpenedChange($event)"
            (selectedValueChange)="onInfoSectionArrayChange()"
            (selectionChange)="onInfoSectionArrayChange()"
          >
            <mat-option>
              <ngx-mat-select-search
                [(ngModel)]="iconsSearchPhrase"
                [searching]="searching"
                placeholderLabel="Wpisz nazwę aby wyszukać..."
                noEntriesFoundLabel="Nie znaleziono"
                [preventHomeEndKeyPropagation]="true"
                [disableScrollToActiveOnOptionsChanged]="true"
                (ngModelChange)="onIconsSearch($event)"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option ngxMatSelectSearchClear> (brak) </mat-option>
            <mat-option *ngFor="let icon of iconsFiltered" [value]="icon">
              <app-icon [icon]="icon"></app-icon>
              {{ icon?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="tiny-editor">
        <h4>Treść sekcji:</h4>
        <editor
          [init]="{
            base_url: '/tinymce',
            suffix: '.min',
            language: 'pl',
            language_url: '/tinymce-i18n/langs/pl.js',
            plugins: ['code', 'link'],
            default_link_target: '_blank'
          }"
          [(ngModel)]="item.content"
          placeholder="Treść sekcji..."
        ></editor>
      </div>

      <mat-action-row>
        <button
          mat-icon-button
          color="warn"
          type="button"
          (click)="deleteSection(item)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
