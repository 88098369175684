import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BlogPost } from '../../models/blog-post.model';
import { HttpResponse } from '@angular/common/http';
import { BlogPostsService } from '../../services/blog-posts.service';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-blog-post-delete-dialog',
  templateUrl: './blog-post-delete-dialog.component.html',
  styleUrls: ['./blog-post-delete-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogPostDeleteDialogComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  blogPostId?: string;
  blogPost?: BlogPost;
  loading = true;
  deleting = false;

  constructor(
    private dialogRef: MatDialogRef<BlogPostDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { id?: string },
    private blogPostsService: BlogPostsService,
    private snackbar: MatSnackBar
  ) {
    this.blogPostId = data.id;
  }

  ngOnInit(): void {
    this.blogPostsService
      .findBlogPostById(this.blogPostId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<BlogPost>) => {
        if (response.ok) {
          this.blogPost = response.body;
        }
        this.loading = false;
      });
  }

  delete(): void {
    this.deleting = true;

    this.blogPostsService
      .deleteBlogPost(this.blogPost)
      .pipe(finalize(() => (this.deleting = false)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<BlogPost[]>) => {
        if (response.ok) {
          this.snackbar.open('Usunięto', null, {
            duration: 1500,
            panelClass: 'success-snackbar',
          });
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
