<div
  role="group"
  class="video-array-form-control-container"
  [attr.aria-labelledby]="_formField?.getLabelId()"
>
  <div class="to-right">
    <button mat-button color="primary" type="button" (click)="addElement()">
      <mat-icon>add</mat-icon> Dodaj element
    </button>
  </div>

  <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-expansion-panel
      cdkDrag
      [cdkDragData]="item"
      [expanded]="openElement === i"
      (opened)="setOpenElement(i)"
      *ngFor="let item of items; index as i"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>
            {{ item.title }}
          </span>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        fxLayout="row"
        fxLayout.lt-lg="column"
        fxLayoutGap="20px"
        fxLayoutGap.lt-lg="0px"
      >
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Tytuł</mat-label>
          <input
            matInput
            [(ngModel)]="item.title"
            (input)="onVideoArrayChange()"
          />
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-lg="column"
        fxLayoutGap="20px"
        fxLayoutGap.lt-lg="0px"
      >
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Link do filmu (YouTube)</mat-label>
          <input
            matInput
            [(ngModel)]="item.youTubeUrl"
            (input)="onVideoArrayChange()"
          />
        </mat-form-field>
      </div>

      <mat-action-row>
        <button
          mat-icon-button
          color="warn"
          type="button"
          (click)="deleteElement(item)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
