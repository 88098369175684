<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2 [hidden]="loading">
        {{ tourId ? "Edycja wyjazdu" : "Tworzenie wyjazdu" }}
      </h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-spinner *ngIf="loading"></mat-spinner>

    <div *ngIf="!loading">
      <form novalidate [formGroup]="form" fxLayout="column" (ngSubmit)="save()">
        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Nazwa</mat-label>
            <input
              matInput
              placeholder="Wyjazd"
              formControlName="name"
              required
            />
            <mat-error *ngIf="nameForm.errors?.required"
              >To pole nie być puste</mat-error
            >
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Kierunek</mat-label>
            <mat-select
              #matSelectInfiniteScroll
              msInfiniteScroll
              (infiniteScroll)="getMoreDestination()"
              [compareWith]="compareDestinations"
              [formControl]="destinationForm"
              placeholder="Wpisz nazwę aby wyszukać"
              [complete]="
                destinationsFilteredLastPage === destinationsFilteredPage
              "
              (openedChange)="destinationFormOpenedChange($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="destinationsFilteringForm"
                  [searching]="searching"
                  placeholderLabel="Wpisz nazwę aby wyszukać..."
                  noEntriesFoundLabel="Nie znaleziono"
                  [preventHomeEndKeyPropagation]="true"
                  [disableScrollToActiveOnOptionsChanged]="true"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option ngxMatSelectSearchClear> (brak) </mat-option>
              <mat-option
                *ngFor="let destination of destinationsFiltered"
                [value]="destination"
              >
                {{ destination?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Skrócona nazwa</mat-label>
            <input
              matInput
              placeholder="Wyjazd"
              formControlName="shortName"
              required
            />
            <mat-error *ngIf="nameForm.errors?.required"
              >To pole nie być puste</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Slug (URL)</mat-label>
            <input
              matInput
              placeholder="element-adresu-strony-wycieczki"
              formControlName="slug"
              required
            />
            <mat-error *ngIf="slugForm.errors?.required"
              >To pole nie być puste</mat-error
            >
            <mat-error *ngIf="slugForm.errors?.value"
              >To pole może zawierać tylko litery, cyfry i myślniki</mat-error
            >
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Data wyjazdu</mat-label>
            <input
              matInput
              formControlName="dateStart"
              [matDatepicker]="picker1"
              (focus)="picker1.open()"
              (click)="!picker1.opened && picker1.open()"
            />
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Typ</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of possibleTourTypes" [value]="type">
                {{ "type." + type | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Rodzaj</mat-label>
            <mat-select formControlName="kinds" multiple>
              <mat-option *ngFor="let kind of possibleTourKinds" [value]="kind">
                {{ "kind." + kind | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Poziom trudności</mat-label>
            <mat-select formControlName="difficulty">
              <mat-option
                *ngFor="let difficulty of possibleTourDifficulties"
                [value]="difficulty"
              >
                {{ "difficulty." + difficulty | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>OG - Tytuł</mat-label>
            <input matInput formControlName="ogTitle" />
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>OG - Opis</mat-label>
            <input matInput formControlName="ogDescription" />
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>META - Opis</mat-label>
            <input matInput formControlName="metaDescription" />
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>META - Słowa kluczowe</mat-label>
            <input matInput formControlName="metaKeywords" />
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Czas trwania [w dniach]</mat-label>
            <input matInput type="number" formControlName="duration" />
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Ilość uczestników</mat-label>
            <input matInput formControlName="participantsLimit" />
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Podstawowa cena</mat-label>
            <app-money-form-control
              formControlName="basePrice"
            ></app-money-form-control>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Dodatkowa cena</mat-label>
            <app-money-form-control
              formControlName="additionalPrice"
            ></app-money-form-control>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Zdjęcie</mat-label>
            <app-image-form-control
              formControlName="photo"
            ></app-image-form-control>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex="70">
            <mat-label>Mapa trasy</mat-label>
            <app-route-point-array-form-control formControlName="routePoints">
            </app-route-point-array-form-control>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-lg="column"
          fxLayoutGap="20px"
          fxLayoutGap.lt-lg="0px"
        >
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Zalety</mat-label>
            <app-string-array-form-control
              formControlName="pros"
            ></app-string-array-form-control>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Cena zawiera</mat-label>
            <app-string-array-form-control
              formControlName="priceInclude"
            ></app-string-array-form-control>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Cena nie zawiera</mat-label>
            <app-string-array-form-control
              formControlName="priceExclude"
            ></app-string-array-form-control>
          </mat-form-field>
        </div>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Opis wyjazdu</mat-label>
          <textarea
            formControlName="description"
            matInput
            placeholder="Opis wyjazdu..."
          ></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Opis trasy</mat-label>
          <textarea
            formControlName="routeDescription"
            matInput
            placeholder="Opis trasy..."
          ></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Plan wyjazdu</mat-label>
          <app-schedule-element-array-form-control formControlName="schedule">
          </app-schedule-element-array-form-control>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Dodatkowe informacje</mat-label>
          <app-info-section-array-form-control formControlName="infoSections">
          </app-info-section-array-form-control>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Filmy z YouTube</mat-label>
          <app-video-array-form-control
            formControlName="videos"
          ></app-video-array-form-control>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Kod przycisku Skaleo</mat-label>
          <textarea
            formControlName="skaleoButtonCode"
            matInput
            placeholder="Kod przycisku Skaleo..."
          ></textarea>
        </mat-form-field>
      </form>
    </div>

    <div mat-card-actions>
      <div
        flex
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxFlex="100"
      >
        <a mat-button [routerLink]="'/tours'"> Anuluj </a>

        <button
          mat-raised-button
          color="primary"
          [class.save-btn-spinner]="saving"
          [disabled]="loading || !form.valid || saving"
          (click)="save()"
        >
          Zapisz
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
